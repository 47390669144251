import styled from 'styled-components';

const MultiSelectChoiceButtonStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  & > button {
    max-width: 365px;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid ${props => props.theme.colors.secondary};
    border-radius: 16px;
    background: ${props => props.theme.colors.white};
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  &:last-child button {
    margin-bottom: 0;
  }
`;

export { MultiSelectChoiceButtonStyled };
