import styled from 'styled-components';

const ErrorMessageItemStyled = styled.div`
  display: flex;
  gap: 11px;
  justify-content: start;
  align-items: center;
  padding-top: 0;
  margin-top: 0;
  p {
    margin: 8px 0 8px 0;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
`;

export { ErrorMessageItemStyled };
