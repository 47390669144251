import React from 'react';
import { CheckboxStyled } from 'src/components/common/Checkbox.styled';

export type CheckboxProps = {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
};

export const Checkbox = ({ label, checked, disabled, onChange }: CheckboxProps): JSX.Element => {
  return (
    <CheckboxStyled>
      <label className='container'>
        <input type='checkbox' checked={checked} onChange={onChange} disabled={disabled} name='controlled' />
        <span className='check-box-effect' tabIndex={0}></span>
        {label}
      </label>
    </CheckboxStyled>
  );
};
