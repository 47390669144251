import React, { Dispatch, FC, MutableRefObject, SetStateAction, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import * as htmlToImage from 'html-to-image';
import FamilyTreePage from 'src/pages/FamilyTreePage/FamilyTreePage';
import { ModalContainer } from 'src/pages/FamilyTreePage/FamilyTreeModal/FamilyTreeModal.styles';
import { FamilyTreeSettingsInterface } from 'src/models/RiskAssessmentQuestionnaire';
import { sendFamilyTreeFile } from 'src/api/familyTreeRequests';

interface Props {
  reactFlowRef: MutableRefObject<HTMLDivElement>
  setFamilyTreeSettings: Dispatch<SetStateAction<FamilyTreeSettingsInterface>>
}

const FamilyTreeModal: FC<Props> = ({
  reactFlowRef,
  setFamilyTreeSettings
}) => {

  const familyTreePDFProcessor = () => (
    setTimeout(() => {
      const element = reactFlowRef.current
      const scrollHeight = element.scrollHeight
      const scrollWidth = element.scrollWidth

      htmlToImage.toJpeg(element, { pixelRatio: 1 }).then(dataUrl => {
        const pdf = new jsPDF({
          orientation: 'l',
          unit: 'pt',
          format: [scrollWidth, scrollHeight]
        })
        const img = new Image()
        img.src = dataUrl

        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()

        pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight)
        pdf.setDocumentProperties({ title: 'My Canсer Family History' })

        const pdfBlob = pdf.output('blob')
        sendPDFFile(pdfBlob)
      })
      setFamilyTreeSettings((prevState) => {
        return {
          ...prevState,
          isOpen: false
        }
      })
    }, 100)
  )

  const sendPDFFile = (file) => {
    const chatId = localStorage.getItem('chatId')
    const formData = new FormData()
    formData.append('file', file, 'tree.pdf')

    sendFamilyTreeFile(chatId, formData).then((res) => {
      if (res) {
        setFamilyTreeSettings((prevState) => {
          return {
            ...prevState,
            isLoading: false
          }
        })
      }
    })
  }

  useEffect(() => {
    return () => clearTimeout(familyTreePDFProcessor())
  }, []);

  return (
    <ModalContainer>
      <FamilyTreePage
        reactFlowRef={reactFlowRef}
        familyTreePDFProcessor={familyTreePDFProcessor}
      />
    </ModalContainer>
  )
}

export default FamilyTreeModal
