import { routes } from 'src/constans/routes';

export const checkUnknownPath = () => {
  const isLoginFinish = localStorage.getItem('isLoginFinish');

  if (isLoginFinish) {
    return routes.HOME;
  } else {
    return routes.LOGIN;
  }
};
