import styled, { css } from 'styled-components';

export const SwitchTabStyled = styled.div`
  background: ${props => (props.isMobile ? props.theme.colors.transparent : props.theme.colors.white)};
  border-radius: 16px;
  margin: 0 16px 16px;
  width: 100%;
  max-width: 946px;
  align-self: center;
  height: ${props => (props.isWidgetActiveTab ? 'calc(100vh - 106px)' : '100%')};
  min-height: ${props => (props.isWidgetActiveTab ? 'unset' : 'calc(100vh - 106px)')};
  display: flex;
  flex-direction: column;

  @supports (height: 100svh) {
    height: ${props => (props.isWidgetActiveTab ? 'calc(100svh - 106px)' : '100%')};
    min-height: ${props => (props.isWidgetActiveTab ? 'unset' : 'calc(100svh - 106px)')};
  }
  
  ${props => props.isMobile && css`
    height: ${props => (props.isWidgetActiveTab ? 'calc(100vh - 164px)' : 'auto')};
    min-height: ${props => (props.isWidgetActiveTab ? 'unset' : 'calc(100vh - 164px)')};
    margin: 0 16px 16px;
    width: auto;
    align-self: stretch;

    @supports (height: 100svh) {
      height: ${props => (props.isWidgetActiveTab ? 'calc(100svh - 97px)' : 'auto')};
      min-height: ${props => (props.isWidgetActiveTab ? 'unset' : 'calc(100svh - 97px)')};
    }
  `}

  ul.nav {
    width: 100%;
    margin: ${props => (props.isMobile ? '0 0 16px 0' : '0 auto 2rem')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${props => (props.theme.colors.white)};
    border-bottom: ${props => (props.isMobile ? '1px solid #F4F5F9' : '2px solid #0152A8')};
    border-top-right-radius: ${props => (props.isMobile ? '12px' : '16px')};
    border-top-left-radius: ${props => (props.isMobile ? '12px' : '16px')};
    border-bottom-right-radius: ${props => (props.isMobile ? '12px' : '0')};
    border-bottom-left-radius: ${props => (props.isMobile ? '12px' : '0')};
    padding-left: 0;
    list-style: none;
  }

  ul.nav li {
    width: 50%;
    padding: ${props => (props.isMobile ? '0.6rem' : '1rem')};
    margin: ${props => (props.isMobile ? '4px' : '0')};
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    color: ${props => (props.theme.colors.primary)};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    
    & svg {
      margin-right: 8px;
      
      & path {
        transition: all 0.7s;
        stroke: ${props => (props.theme.colors.primary)};
      }
    }
    
    & .chat-icon {
      & path:first-child {
        fill: white;
        stroke: ${props => (props.theme.colors.primary)};
      }
      
      & path {
        transition: all 0.7s;
        stroke: ${props => (props.theme.colors.transparent)};
        fill: ${props => (props.theme.colors.primary)};
      }
    }
  }

  ul.nav li:first-child {
    border-top-right-radius: ${props => (props.isMobile ? '12px' : '16px')};
    border-top-left-radius: ${props => (props.isMobile ? '12px' : '16px')};
    border-bottom-right-radius: ${props => (props.isMobile ? '12px' : '0')};
    border-bottom-left-radius: ${props => (props.isMobile ? '12px' : '0')};
  }

  ul.nav li:last-child {
    border-top-right-radius: ${props => (props.isMobile ? '12px' : '16px')};
    border-top-left-radius: ${props => (props.isMobile ? '12px' : '16px')};
    border-bottom-right-radius: ${props => (props.isMobile ? '12px' : '0')};
    border-bottom-left-radius: ${props => (props.isMobile ? '12px' : '0')};
  }

  ul.nav li.active {
    background: #0152A8;
    color: ${props => (props.theme.colors.white)};

    & svg {
      & path:first-child  {
        stroke: ${props => (props.theme.colors.white)};
      }
    }

    & .chat-icon {
      & path:first-child {
        fill: ${props => (props.theme.colors.primary)};
      }
      
      & path {
        fill: ${props => (props.theme.colors.white)};
      }
    }
  }

  .outlet {
    background: ${props => (props.theme.colors.white)};
    border-radius: 16px;
    padding: ${props => (props.isWidgetActiveTab ? 0 : props.isMobile ? '16px' : '0 24px 24px')};
    position: relative;
    height: calc(100% - ${props => (props.isWidgetActiveTab ? 66 : 0)}px);
    flex-grow: 1;
    
    ${props => props.isMobile && css`
      height: ${props => (props.isWidgetActiveTab ? 'calc(100% - 68px)' : '100%')};
      min-height: ${props => (props.isWidgetActiveTab ? 'unset' : 'calc(100svh - 198px)')};
    `}
  }
  /* Tab Content Styles */
  .TabContent {
    font-size: 2rem;
    text-align: center;
  }
`
