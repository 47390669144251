import styled from 'styled-components';

const UserInputCounterStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  & > p {
    margin: 0;
  }
`;

const CounterButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  & > button {
    width: 48px;
    height: 48px;
    border: 1px solid ${props => props.theme.colors.secondary};
    border-radius: 16px;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.secondary};
    cursor: pointer;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    
    &:disabled {
      border: none;
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
`;

const CounterValue = styled.p`
  width: 24px;
  margin: 0 10px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.additionalText};
`

export { UserInputCounterStyled, CounterButtonsContainer, CounterValue };
