import { postRequest } from 'src/api/apiPost';
import { FIRST_RESET_EMAIL, RESET_PASSWORD_CHANGE_PASSWORD, RESET_PASSWORD_TOKEN_VALIDATION } from 'src/config';
import {
  ResetPasswordChangePasswordRequest,
  ResetPasswordCheckEmailRequest,
  ResetPasswordTokenValidationRequest,
} from 'src/models/ResetPassword';

export const resetPasswordCheckEmailRequest = (requestData: ResetPasswordCheckEmailRequest) => {
  return postRequest(FIRST_RESET_EMAIL, requestData).then(response => {
    return response
  });
};

export const resetPasswordTokenValidationRequest = (requestData: ResetPasswordTokenValidationRequest) => {
  return postRequest(RESET_PASSWORD_TOKEN_VALIDATION, requestData).then(response => {
    return response.json().then(data => ({
      data: data,
      status: response.status,
    }));
  });
};

export const resetPasswordChangePasswordRequest = (requestData: ResetPasswordChangePasswordRequest) => {
  return postRequest(RESET_PASSWORD_CHANGE_PASSWORD, requestData).then(response => {
    return response
  });
};
