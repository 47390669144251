import { routes } from 'src/constans/routes';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAuthorised } from 'src/hooks/authorised';

export const UnknownRoute = () => {
  const { isAuthorised } = useGetAuthorised();
  const currentRoute = isAuthorised ? routes.HOME : routes.LOGIN;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(currentRoute);
  }, [currentRoute, isAuthorised, navigate]);

  return <></>;
};
