import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import Modal from 'src/components/common/Modal/Modal'
import { MultiSelectChoiceButton } from 'src/components/RiskAssessmentQuestionnaire/MultiSelectChoiceButton'
import { Input } from 'src/components/common';
import {updateUserNotificationsSettings, getUserNotificationsSettings} from 'src/api/userNotificationsSettings';
import {UserNotificationsSettingsResponse} from 'src/models/UserNotificationsSettings';
import {SpanStyled} from 'src/pages/Home/components/NotificationModal/NotifiicationModal.style';

interface Props {
  onClose: (p: boolean) => void
  currentChatId: number
}

const NotificationModal: FC<Props> = ({ onClose, currentChatId }) => {
  const [hasPhoneNumberInput, setHasPhoneNumberInput] = useState(false)
  const [phoneValue, setPhoneValue] = useState('')
  const [formattedPhoneValue, setFormattedPhoneValue] = useState('')
  const [checkboxesState, setCheckboxesState] = useState([
    { title: 'Via SMS/text', checked: false, id: 'phone' },
    { title: 'Via email', checked: false, id: 'email' }
  ])

  useEffect(() => {
    getUserNotificationsSettings(currentChatId).then((res: UserNotificationsSettingsResponse) => {
      const updatedCheckboxes = checkboxesState.map((checkbox) => (
        {...checkbox, checked: checkbox.id === 'email' ? res.isEmailNotificationEnabled : res.isPhoneNotificationEnabled}
      ))
      setCheckboxesState(updatedCheckboxes)
      setPhoneValue(res.phoneNumber || '')
    })
  }, [currentChatId])

  const submitHandler = useCallback(() => {
    function submitForm() {
      updateUserNotificationsSettings({
        isEmailNotificationEnabled: checkboxesState.find(checkbox => checkbox.id === 'email').checked,
        isPhoneNotificationEnabled: checkboxesState.find(checkbox => checkbox.id === 'phone').checked,
        phoneNumber: phoneValue,
        chatId: currentChatId
      }).then(isSuccess => {
        if (isSuccess) {
          onClose(false)
        }
      })
    }

    const isPhoneNotificationChecked = checkboxesState.find(checkbox => checkbox.id === 'phone').checked
    if (isPhoneNotificationChecked && !phoneValue?.length) {
      setHasPhoneNumberInput(true)
      if (phoneValue) {
        submitForm()
      }
    } else {
      submitForm()
    }
  }, [checkboxesState, onClose, phoneValue])

  const normalizePhoneInput = (value?: string, previousValue?: string) => {
    if (!value) return value
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue
      if (cvLength < 7) return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`
      return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)} ${currentValue.slice(6, 10)}`
    }
  }

  const preparePhoneToSubmit = (phone: string): string => phone.replace(/\s/g, '')

  const notificationButtons = useMemo(() => {
    return ([
      {
        title: 'Submit',
        className: 'submitButton',
        callback: submitHandler,
        disable: hasPhoneNumberInput ? phoneValue.length != 10 : checkboxesState.every(checkbox => !checkbox.checked)
      }
    ])
  }, [checkboxesState, hasPhoneNumberInput, phoneValue.length, submitHandler])

  const onChange = (id: string) => {
    const updatedCheckboxes = checkboxesState.map((checkbox) => (checkbox.id === id ? {...checkbox, checked: !checkbox.checked} : checkbox))
    setCheckboxesState(updatedCheckboxes)
  }

  const notificationModalTitle = useMemo(() => {
    return hasPhoneNumberInput ? 'Please provide your phone number below' : 'How do you want to receive notifications?'
  }, [hasPhoneNumberInput])

  const handlePhoneInput = (phone: string) => {
    const phoneValue = preparePhoneToSubmit(phone)
    const isDigitsOnly = /^\d*$/.test(phoneValue)

    if (phoneValue.length > 10 || !isDigitsOnly) return

    setPhoneValue(preparePhoneToSubmit(phone));
    setFormattedPhoneValue(normalizePhoneInput(phone));
  }

  return (
    <Modal
      title={notificationModalTitle}
      buttons={notificationButtons}
      onClose={onClose}
    >
      {hasPhoneNumberInput ? (
        <Input
          isLarge={false}
          name='phone_number'
          type='text'
          value={formattedPhoneValue}
          onChange={(e) => handlePhoneInput(e.target.value)}
          labelText='Phone number'
          placeholder='000 000 0000'
          className='phone-number'
        />
      ) : (
        checkboxesState.map(checkbox => (
          <MultiSelectChoiceButton
            key={checkbox.id}
            label={checkbox.title}
            checked={checkbox.checked}
            onChange={() => onChange(checkbox.id)}
          />
        ))
      )}
      {(hasPhoneNumberInput && phoneValue.length != 10) && (
        <SpanStyled>Please input only 10 digits without using brackets or dashes</SpanStyled>
      )}
    </Modal>
  )
}

export default NotificationModal
