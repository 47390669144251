import { getStatusForProvideData } from 'src/api/getStatusForProvideData';
import React, { useEffect, useState } from 'react';
import { getRoutes, RoutesStatuses } from 'src/utils/getRoutes';
import { useGetChatId } from 'src/hooks/chatId';
import { useNavigate } from 'react-router-dom';
import { useGetLoginFinish } from 'src/hooks/loginFinish';
import { useGetAuthorised } from 'src/hooks/authorised';
import {useGetFirstLogin} from 'src/hooks/firstLogin';

export const ProvideDataHandler = () => {
  const [currentRoute, setCurrentRoute] = useState('');
  const { currentChatId } = useGetChatId();
  const { isLoginFinish, setIsLoginFinish } = useGetLoginFinish();
  const [provideDataStatus, setProvideDataStatus] = useState();
  const { setIsAuthorised } = useGetAuthorised();
  const { setIsFirstLogin } = useGetFirstLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentChatId) {
      getStatusForProvideData(currentChatId).then(res => {
        return res.json()
      }).then(res => {
        setProvideDataStatus(res?.user_info_state);
      });
    }
  }, [currentChatId, currentRoute]);

  useEffect(() => {
    if (provideDataStatus === RoutesStatuses.DONE) {
      setIsLoginFinish(true);
      setIsAuthorised(true);
    }
    if (provideDataStatus) {
      setIsFirstLogin(true);
      setCurrentRoute(getRoutes(provideDataStatus));
    }
  }, [provideDataStatus, setIsAuthorised, setIsLoginFinish]);

  useEffect(() => {
    navigate(currentRoute);
  }, [currentRoute, navigate, isLoginFinish]);

  return <></>;
};
