import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'src/components/common';
import { routes } from 'src/constans/routes';
import { useProvideDataContactNamesMutation } from 'src/hooks/api/useProvideData';
import { useGetChatId } from 'src/hooks/chatId';
import { checkErrorFromRequest } from 'src/utils/checkErrorFromRequest';
import {
  ProvideDataContentStyled,
  ProvideDataNamesStyled,
  ProvideDataErrorMessage,
  SubmitButton,
  ProvideDataContent
} from 'src/pages/ProvideDataPage/style/ProvideDataNames.styled';
import { Logo } from 'src/components/common/Logo/Logo';
import { ErrorsInterface } from 'src/models/ProvideData';
import { getDeviceType } from 'src/utils/inspectDeviceType';

import { getValidationSchema } from './validationSchema';

export const ProvideDataNames = () => {
  const navigate = useNavigate();
  const isMobile = getDeviceType() === 'mobile';
  const { currentChatId } = useGetChatId();
  const { mutateAsync: provideDataContactNamesRequest } = useProvideDataContactNamesMutation();

  return (
    <ProvideDataNamesStyled>
      <Logo isMobile={isMobile} />
      <ProvideDataContent>
        <p>Please provide information below to finish creating your account </p>
        <Formik
          initialValues={{ first_name: '', last_name: '', email: '' }}
          validate={(values) => {
            const errors = {} as ErrorsInterface
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Please enter a valid email address';
            }
            return errors
          }}
          validationSchema={getValidationSchema(true)}
          onSubmit={values =>
            provideDataContactNamesRequest({chat_id: currentChatId, first_name: values.first_name, last_name: values.last_name, email: values.email }).then(res => {
              if (res.status === 200) {
                navigate(routes.NOTIFICATIONS_CHOOSE);
              } else {
                return navigate(checkErrorFromRequest(res.status));
              }
            })
          }
        >
          {({ values, errors, handleChange, handleBlur, isValid, dirty }) => {
            return (
              <Form>
                <ProvideDataContentStyled isMobile={isMobile}>
                  <Input
                    isLarge={false}
                    name='first_name'
                    type='text'
                    value={values.first_name}
                    onChange={handleChange}
                    labelText='First name'
                  />
                  <Input
                    isLarge={false}
                    name='last_name'
                    type='text'
                    value={values.last_name}
                    onChange={handleChange}
                    labelText='Last name'
                  />
                  <Input
                    isLarge={false}
                    name='email'
                    type='email'
                    value={values.email}
                    errors={errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelText='Email address'
                    isLastElement={true}
                  >
                    {errors.email && <ProvideDataErrorMessage>{errors.email}</ProvideDataErrorMessage>}
                  </Input>
                </ProvideDataContentStyled>
                <SubmitButton isMobile={isMobile}>
                  <Button type='submit' disable={!(isValid && dirty)}>Continue</Button>
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </ProvideDataContent>
    </ProvideDataNamesStyled>
  );
};
