import { object, string } from 'yup';

export const getValidationSchema = (isFirst: boolean) => {
  return isFirst
    ? object().shape({
      username: string().min(1).required('Field is required'),
    })
    : object().shape({
      username: string().min(1).required('Field is required'),
      password: string().min(8, 'Must be at least 8 characters').required('Field is required'),
    });
};
