import styled, { css } from 'styled-components'

export const NodeContainer = styled.div`
  border: 4px solid ${props => props.theme.colors.nodeContainerBorder};
  border-radius: 4px;
  background-color: ${props => (props.isEntryNode ? props.theme.colors.personalCustomNodeBackgroundColor : props.theme.colors.white)};
  padding: 8px;
  box-shadow: 0 0 2px 0 ${props => props.theme.colors.nodeBoxShadow};
  display: flex;
  width: 180px;
  height: 180px;
  box-sizing: border-box;
  
  ${props => props.isEntryNode && css`
    border: none;
  `}
  
  & svg {
    & ~ div {
      margin-left: 8px;
    }
    min-width: 22px;
    width: 22px;
    height: 22px;
  }
`

export const NodeTextBox = styled.div`
  word-break: break-word;
`

const NodeTextItem = styled.p`
  color: ${props => props.theme.colors.additionalText};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 20px;
  font-size: 14px;
  margin: 0;
`

export const NameTextItem = styled(NodeTextItem)`
  font-size: ${props => props.relativeInfoFontStyle.fontSize}px;
  font-style: normal;
  font-weight: 600;
  line-height: ${props => props.relativeInfoFontStyle.lineHeight}px;
`

export const RelativeTextItem = styled(NodeTextItem)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
`

export const DiagnosesTextItem = styled(NodeTextItem)`
  font-style: normal;
  font-weight: 500;
  line-height: ${props => props.diagnosesFontStyle.lineHeight}px;
  font-size: ${props => props.diagnosesFontStyle.fontSize}px;
`

export const TechnicalNodeContainer = styled.div`
  width: 1px;
  height: 1px;
`
