import React from 'react';
import { unescape } from 'lodash'
import {
  QuestionTitleContainer,
  QuestionTitleStyled
} from 'src/components/RiskAssessmentQuestionnaire/style/QuestionTitle.styled';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import QuestionnaireLogo from 'src/pages/RiskAssessmentQuestionnairePage/QuestionnaireLogo/QuestionnaireLogo';

export const QuestionTitle = ({
  children,
  index = 0,
  isLastElement,
  hasAvatarLogo = false
}) => {
  const isMobile = getDeviceType() === 'mobile'
  const isFirstElement = index === 0

  return (
    <QuestionTitleContainer isMobile={isMobile} isLastElement={isLastElement}>
      {isFirstElement && hasAvatarLogo && <QuestionnaireLogo/>}
      <QuestionTitleStyled
        isMobile={isMobile}
        dangerouslySetInnerHTML={{
          __html: unescape(children)
        }}
      />
    </QuestionTitleContainer>
  )
};
