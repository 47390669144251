import { postRequest } from 'src/api/apiPost';
import { GET_VOICE, USER_INFO_PREFERENCE } from 'src/config';
import { ChooseNotificationRequest, VoiceChatNotificationRequest } from 'src/models/ProvideData';

export const chooseNotificationRequest = (requestData: ChooseNotificationRequest) => {
  return postRequest(USER_INFO_PREFERENCE, requestData).then(response => {
    return response.json().then(data => ({
      data: data,
      status: response.status,
    }));
  });
};

export const voiceChatNotificationRequest = (requestData: VoiceChatNotificationRequest) => {
  return postRequest(GET_VOICE, { bot_type_preference: requestData.bot_type_preference }, { chatId: requestData.chat_id }).then(response => {
    return response.json().then(data => ({
      data: data,
      status: response.status,
    }));
  });
};
