import styled from 'styled-components';
import { LoginPageContent } from 'src/pages/Login/LoginPage/LoginPage.style'

export const ReceiveNotificationsPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
  min-height: 100vh;

  @supports (height: 100svh) {
    min-height: 100svh;
  }
`

export const ReceiveNotificationsPageContent = styled(LoginPageContent)`
  
  & p {
    text-align: center;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${props => props.theme.colors.additionalText};
    margin-bottom: 56px;
    flex-grow: ${props => (props.isMobile ? 1 : 0)};
  }
`

export const ReceiveNotificationsPageContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.white};
    width: 100%;
    max-width: 365px;
    height: 48px;
    border-radius: 16px;
    color: ${props => props.theme.colors.secondary};
    border: 1px solid ${props => props.theme.colors.secondary};
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    
    @media(hover: hover) {
      &:hover {
        border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
        background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
        color: ${props => props.theme.colors.singleSelectButtonTextColor};
      }
    }

    &:active {
      border-color: ${props => props.theme.colors.ghostButtonActiveBorderColor};
      background-color: ${props => props.theme.colors.userInputBorder};
      color: ${props => props.theme.colors.ghostButtonActiveTextColor};
    }
  }
  
  .active {
      border: 1px solid ${props => props.theme.colors.additionalText};
      color: ${props => props.theme.colors.additionalText};
    }
    
  .submitButton {
    background-color: ${props => props.theme.colors.primary};
    width: 100%;
    max-width: 365px;
    height: 48px;
    color: ${props => props.theme.colors.white};
    border-radius: 16px;
    border: ${props => (props.isMobile ? '1px solid' : 'none')};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    box-shadow: 0px -4px 16px rgba(149, 169, 200, 0.15);

    @media(hover: hover) {
      &:hover {
        background-color: ${props => props.theme.colors.mainButtonHoverColor};
        color: ${props => props.theme.colors.white};
      }
    }

    &:active {
      background-color: ${props => props.theme.colors.mainButtonFocusColor};
      color: ${props => props.theme.colors.white};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      color: ${props => props.theme.colors.white};
      cursor: auto;
    }
  }
`
