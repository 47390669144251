import styled from 'styled-components';

export const CreatePasswordFormInputWithSvg = styled.div.attrs((props) => ({
  'className': props.isLastItem && 'last_element'
}))`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  svg {
    position: absolute; //you can make icon on the input like this.
    top: 50%; //icon will be center of the input from top to bottom.
    right:  12px; //right position.
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  &.last_element {
    flex-grow: ${props => (props.isMobile ? 1 : 0)};
    
    & > div {
      justify-content: flex-start;
      margin-bottom: 40px;
    }
    
    & svg {
      top: 37px;
      transform: none;
    }
  }
`

export const PasswordErrorMessage = styled.span`
  color: ${props => props.theme.colors.errorMessage};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  align-self: flex-start;
  position: absolute;
  top: 74px;
  left: 0;
`

export const SubmitButton = styled.div`
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.primary};
    width: 100%;
    max-width: 365px;
    height: 48px;
    border-radius: 16px;
    color: ${props => props.theme.colors.white};
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.mainButtonHoverColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.mainButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
`;
