import { useLocalStorage } from 'usehooks-ts';
import { useEffect } from 'react';

export function useGetLoginFinish() {
  const [isLoginFinish, setIsLoginFinish] = useLocalStorage('isLoginFinish', false);

  useEffect(() => {
    return setIsLoginFinish(isLoginFinish);
  }, [isLoginFinish, setIsLoginFinish]);

  return { isLoginFinish, setIsLoginFinish };
}
