import styled from 'styled-components';

export const CheckboxStyled = styled.div`
  display: flex;
  flex-direction: column;

  label {
    cursor: pointer;
    text-align: start;
    align-items: center;
    justify-content: start;
    display: flex;
    flex-direction: row;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${props => props.theme.colors.secondary};
  }

  label .check-box-effect {
    display: inline-block;
    position: relative;
    background-color: transparent;
    min-width: 22px;
    min-height: 22px;
    border: 1px solid ${props => props.theme.colors.secondary};
    border-radius: 8px;
    margin-right: 12px;
    box-sizing: border-box;
  }

  label .check-box-effect:before {
    content: '';
    width: 0;
    height: 2px;
    border-radius: 8px;
    background: ${props => props.theme.colors.primary};
    position: absolute;
    transform: rotate(45deg);
    top: 8px;
    left: 6px;
    transition: width 50ms ease 50ms;
    transform-origin: 0 0;
  }

  label .check-box-effect:after {
    content: '';
    width: 0;
    height: 2px;
    border-radius: 8px;
    background: ${props => props.theme.colors.primary};
    position: absolute;
    transform: rotate(315deg);
    top: 14px;
    left: 9px;
    transition: width 50ms ease;
    transform-origin: 0 0;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked + .check-box-effect {
    background-color: ${props => props.theme.colors.primary};
    border: none;
    border-radius: 8px;
  }

  input[type='checkbox']:checked + .check-box-effect:after {
    width: 12px;
    background: ${props => props.theme.colors.white};
  }

  input[type='checkbox']:checked + .check-box-effect:before {
    width: 8px;
    background: ${props => props.theme.colors.white};
  }

  label:hover {
    color: ${props => props.theme.colors.primary};
  }

  :focus {
    border: 1px solid ${props => props.theme.colors.primary};
  }

  input[type='checkbox']:disabled + .check-box-effect {
    background-color: ${props => props.theme.colors.primary};
    cursor: default;
  }

  input[type='checkbox']:disabled + .check-box-effect:before {
    background-color: ${props => props.theme.colors.white};
  }
  input[type='checkbox']:disabled + .check-box-effect:after {
    background-color: ${props => props.theme.colors.white};
  }
`;
