import { postRequest } from 'src/api/apiPost';
import { CREATE_PASSWORD } from 'src/config';
import { CreatePasswordRequest } from 'src/models/Login';

export const createPassRequest = (requestData: CreatePasswordRequest) => {
  return postRequest(CREATE_PASSWORD, requestData).then(response => {
    return response.json().then(data => ({
      data: data,
      status: response.status,
    }));
  });
};
