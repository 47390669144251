import React from 'react';
import { IntroPage } from 'src/components/RiskAssessmentQuestionnaire/IntroPage';
import { SingleSelect } from 'src/components/RiskAssessmentQuestionnaire/SingleSelect';
import { FinishPage } from 'src/components/RiskAssessmentQuestionnaire/FinishPage';
import { CustomTags } from 'src/constans/constans';

export const SingleSelectPage = ({
  data,
  onNext,
  chatId,
  familyTreeSettings,
  setFamilyTreeSettings,
  stopPlayAndResetAudioQueue
}) => {
  const hasInitTag = data.custom_tags.some(tag => tag === CustomTags.INIT)
  const hasFinishTag = data.custom_tags.some(tag => tag === CustomTags.FINISH)
  const findCurrentComponent = (data) => {
    if (hasInitTag) {
      return (
        <IntroPage
          data={data}
          onNext={onNext}
          chatId={chatId}
          stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
        />
      )
    }
    if (hasFinishTag) {
      return (
        <FinishPage
          data={data}
          familyTreeSettings={familyTreeSettings}
          stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
        />
      )
    }

    return (
      <SingleSelect
        data={data}
        onNext={onNext}
        chatId={chatId}
        setFamilyTreeSettings={setFamilyTreeSettings}
        stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
      />
    )
  }
  return (
    <>
      {findCurrentComponent(data)}
    </>
  );
}
