export const INPUT = 'input';
export const BUTTON = 'button';
export const CHECKBOX = 'checkbox';
export const DEFAULT = 'default';
export const BACK_ARROW = 'backArrow';
export const BACK_ARROW_MOBILE = 'backArrowMobile';
export const NEXT_ARROW = 'nextArrow';
export const CARET_DOWN = 'caretDown';
export const CARET_UP = 'caretUp';
export const USER_ID = 'userId';
export const POSTBACK = 'postback';
export const EMAIL = 'email';
export const NUMBER = 'number';
export const TELEPHONE = 'tel';
export const TEXT = 'text';
export const NAME = 'name';
export const QUESTION = 'question';
export const SEARCH = 'search';
export const CLOSE = 'close';
export const CLOSE_MOBILE = 'closeMobile';
export const PLUS = 'plus';
export const MULTIPLE_TYPE = 'multiple';
export const SHOW_HIDE_SVG = 'showHideSvg';
export const CHECK_ICON_SVG = 'checkIconSvg';
export const VOICE_CHAT_SVG = 'voiceChatSvg';
export const WRONG_ICON_SVG = 'wrongIconSvg';
export const DROPDOWN_CLOSE = 'dropdownClose';
export const CHEVRON_DOWN = 'chevronDown';
export const LIVING_UNAFFECTED_FEMALE = 'livingUnaffectedFemale';
export const LIVING_UNAFFECTED_MALE = 'livingUnaffectedMale';
export const DECEASED_FEMALE = 'deceasedFemale';
export const DECEASED_MALE = 'deceasedMale';
export const UNKNOWN_LIVING_DECEASED_STATUS = 'unknownLivingDeceasedStatus';
export const LIVING_AFFECTED_FEMALE = 'livingAffectedFemale';
export const LIVING_AFFECTED_MALE = 'livingAffectedMale';
export const DECEASED_AFFECTED_FEMALE = 'deceasedAffectedFemale';
export const DECEASED_AFFECTED_MALE = 'deceasedAffectedMale';
export const BURGER_MENU = 'burgerMenu';
export const BURGER_MENU_CLOSE = 'burgerMenuClose';
export const QUESTIONNAIRE_TAB_ICON = 'questionnaireTabIcon';
export const CHAT_TAB_ICON = 'chatTabIcon';
export const SPEAKER = 'speaker';
export const SPEAKER_OFF = 'speakerOff';
export const CHANGE_NOTIFICATIONS = 'changeNotifications';
export const SIGN_OUT = 'signOut';
export const BURGER_MENU_MOBILE = 'burgerMenuMobile';
export const BURGER_MENU_CLOSE_MOBILE = 'burgerMenuCloseMobile';
export const MICROPHONE = 'microphone';
export const PLAY = 'play'
export const MICROPHONE_MOBILE = 'microphoneMobile';
