import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Input } from 'src/components/common';
import { useResetPasswordCheckEmailMutation } from 'src/hooks/api/useResetPassword';
import { useNavigate } from 'react-router-dom';
import { emailOrNumberFormatter } from 'src/pages/ResetPasswordPage/helpers/emailOrNumberFormatter';
import {
  ResetPasswordPageContent,
  ResetPasswordPageStyled,
  SubmitButton
} from 'src/pages/ResetPasswordPage/ResetPasswordPage.styled';
import { routes } from 'src/constans/routes';
import { Logo } from 'src/components/common/Logo/Logo';
import { useCompleteForgotPasswordFlow } from 'src/hooks/completeForgotPasswordFlow';
import { getDeviceType } from 'src/utils/inspectDeviceType';

import { validationSchema } from './validationSchema';

export const ResetPasswordPage = () => {
  const { mutateAsync: resetPasswordCheckEmail } = useResetPasswordCheckEmailMutation();
  const [infoMessage, setInfoMessage] = useState('');
  const navigate = useNavigate();
  const isMobile = getDeviceType() === 'mobile';
  const { setCompleteForgotPassword } = useCompleteForgotPasswordFlow();
  return (
    <ResetPasswordPageStyled>
      <Logo isMobile={isMobile} />
      <ResetPasswordPageContent isMobile={isMobile}>
        <h2>Trouble logging in?</h2>
        <p>Enter your phone number (if you provided it while creating your account)
          or your email address and you will receive a message with a link to reset your password.</p>
        <Formik
          initialValues={{ emailOrNumber: '' }}
          onSubmit={values => {
            const valueForRequest = emailOrNumberFormatter(values.emailOrNumber)
            resetPasswordCheckEmail(valueForRequest).then(() => {
              setCompleteForgotPassword(true);
              navigate(routes.ADDITIONAL_SCREEN_FOR_RESET_PASSWORD);
              return setInfoMessage('success');
            });
          }}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, isValid, dirty }) => (
            <Form>
              <Input
                isLarge={false}
                name='emailOrNumber'
                value={values.emailOrNumber}
                onChange={handleChange}
                labelText='Email address or phone number'
                type='text'
              />
              <SubmitButton isMobile={isMobile}>
                <Button type='submit' disable={!(isValid && dirty)}>Send link</Button>
              </SubmitButton>
              {infoMessage && <p>{infoMessage}</p>}
            </Form>
          )}
        </Formik>
      </ResetPasswordPageContent>
    </ResetPasswordPageStyled>
  );
};
