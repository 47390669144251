import React from 'react'
import {getDeviceType} from 'src/utils/inspectDeviceType'

import Avatar from './Avatar.png'
import {
  AvatarLogo,
  QuestionnaireLogoContainer
} from './QuestionnaireLogo.styled';

const QuestionnaireLogo = () => {
  const isMobile = getDeviceType() === 'mobile';

  return (
    <QuestionnaireLogoContainer isMobile={isMobile}>
      <AvatarLogo src={Avatar} alt='avatar' />
    </QuestionnaireLogoContainer>
  )
}

export default QuestionnaireLogo
