import { postRequest } from 'src/api/apiPost';
import { INIT_USER } from 'src/config';
import { FirstLoginRequest } from 'src/models/Login';

export const firstLoginRequest = (requestData: FirstLoginRequest) => {
  return postRequest(INIT_USER, requestData).then(response =>
    response.json().then(data => ({
      data: data,
      status: response.status,
    })),
  );
};
