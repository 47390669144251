import React, { useLayoutEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validateYupSchemaMultiErrors } from 'src/utils/yup';
import { CreatePasswordForm } from 'src/components/CreatePasswordForm/CreatePasswordForm';
import { useGetChatId } from 'src/hooks/chatId';
import { useCreatePasswordMutation, useLoginMutation } from 'src/hooks/api/useLogin';
import {
  useResetPasswordChangePasswordMutation,
  useResetPasswordTokenValidationMutation,
} from 'src/hooks/api/useResetPassword';
import { useUserName } from 'src/hooks/userName';
import { checkErrorFromRequest } from 'src/utils/checkErrorFromRequest';
import { routes } from 'src/constans/routes';
import {
  CreatePasswordPageContent,
  CreatePasswordPageStyled
} from 'src/pages/CreatePasswordPage/CreatePasswordPage.styled';
import { Logo } from 'src/components/common/Logo/Logo';
import { getDeviceType } from 'src/utils/inspectDeviceType';

import { validationSchema } from './validationSchema';

export const CreatePasswordPage = () => {
  const isMobile = getDeviceType() === 'mobile';
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  const isReset = !!token;
  const { currentChatId } = useGetChatId();
  const navigate = useNavigate();
  const { userName } = useUserName();
  const [isTokenAvailable, setIsTokenAvailable] = useState(false);
  const [ currentRoute ] = useState('');
  const { mutateAsync: createPassRequest } = useCreatePasswordMutation();
  const { mutateAsync: resetPasswordCheckTokenRequest } = useResetPasswordTokenValidationMutation();
  const { mutateAsync: resetPasswordChangePassword } = useResetPasswordChangePasswordMutation();
  const { mutateAsync: loginRequest } = useLoginMutation();

  useLayoutEffect(() => {
    isReset
      ? resetPasswordCheckTokenRequest({ token }).then(res => {
        if (res.status === 200) {
          setIsTokenAvailable(true);
        } else {
          return navigate(routes.LOGIN);
        }
      })
      : setIsTokenAvailable(true);
  }, [token, isReset, resetPasswordCheckTokenRequest, navigate]);

  return (
    <CreatePasswordPageStyled>
      <Logo isMobile={isMobile} />
      <CreatePasswordPageContent>
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          onSubmit={values => {
            if (!isReset) {
              createPassRequest({ password: values.password, chat_id: currentChatId }).then(res => {
                if (res.status === 200) {
                  return loginRequest({ username: userName, password: values.password }).then(result => {
                    return result.status === 200
                      ? navigate(routes.PROVIDE_DATA_NAMES)
                      : navigate(checkErrorFromRequest(res.status));
                  });
                } else {
                  navigate(checkErrorFromRequest(res.status));
                }
              });
            } else {
              resetPasswordChangePassword({ new_password: values.password, token: token }).then(() => {
                return navigate(currentRoute);
              });
            }
          }}
          validationSchema={validationSchema}
          validate={values => validateYupSchemaMultiErrors(values, validationSchema)}
        >
          {({ values, handleChange, errors, isValid, dirty}) => (
            <CreatePasswordForm
              values={values}
              errors={errors}
              handleChange={handleChange}
              tokenAvailable={isTokenAvailable}
              isValid={isValid}
              dirty={dirty}
            />
          )}
        </Formik>
      </CreatePasswordPageContent>
    </CreatePasswordPageStyled>
  );
};
