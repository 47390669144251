import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 82, 168, .3);
`

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  max-width: 292px;
  box-shadow: 7px 14px 28px 4px rgba(109, 114, 119, 0.03);
  background: ${props => props.theme.colors.white};
  border-radius: 16px;
  box-sizing: border-box;
`

export const Title = styled.p`
  margin: 0 0 16px;
  font-family: ${props => props.theme.fonts};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: ${props => props.theme.colors.additionalText};
`

export const Body = styled.div`
  margin-bottom: 16px;
  
  & input.phone-number {
    border: 1px solid ${props => props.theme.colors.userInputBorder};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  & button {
    width: ${props => (props.isMobile ? '100%' : '260px')};
    height: 48px;
    min-height: 48px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  
  & .submitButton {
    background-color: ${props => props.theme.colors.secondaryButtonDefaultColor};
    color: ${props => props.theme.colors.white};
    border: ${props => (props.isMobile ? '1px solid' : 'none')};
    box-shadow: 0px -4px 16px rgba(149, 169, 200, 0.15);
    margin-bottom: 12px;
    
    &:last-child {
      margin-bottom: 0;
    }

    @media (hover: hover) {
      &:hover {
        background-color: ${props => props.theme.colors.secondaryButtonHoverColor};
      }
    }

    &:active {
      background-color: ${props => props.theme.colors.secondaryButtonFocusColor};
    }
    
    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
  
  & .ghostButton {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.secondary};
    border: 1px solid ${props => props.theme.colors.secondary};
    
    &:hover {
      background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
      border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
      color: ${props => props.theme.colors.singleSelectButtonTextColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.userInputBorder};
      border-color: ${props => props.theme.colors.ghostButtonActiveBorderColor};
      color: ${props => props.theme.colors.ghostButtonActiveTextColor};
    }
  }
`
