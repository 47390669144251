import { CustomAttributes, Tabs } from 'src/constans/constans'

const parseAttributes = (payload) => {
  const REGEX = /\\u([0-9]|[a-fA-F])([0-9]|[a-fA-F])([0-9]|[a-fA-F])([0-9]|[a-fA-F])/g
  const spliterator = '?attributes='

  if (payload && typeof payload === 'string' && payload.includes(spliterator)) {
    const attributes = payload.split(spliterator)
    if (attributes[1] !== undefined) {
      const attributesDecode = window.atob(attributes[1]).substr(2)
      const attributesStr = JSON.stringify(attributesDecode).replace(REGEX, '')
      return JSON.parse(JSON.parse(attributesStr))
    }
  }
  return []
}

export const processingByAttribute = (payload: string, setActiveTab: (value: Tabs) => void): void => {
  const parsedAttributes = parseAttributes(payload)
  if (parsedAttributes.length) {
    parseAttributes(payload).map(attribute => {
      switch (attribute.name) {
        case CustomAttributes.GO_TO_QUESTIONNAIRES:
          setActiveTab(Tabs.TAB_2)
          break
      }
    })
  } else {
    setActiveTab(Tabs.TAB_1)
  }
}
