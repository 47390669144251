import { routes } from 'src/constans/routes';
import { UserStatusResponse } from 'src/models/UserStatus';
import { checkUnknownPath } from 'src/utils/checkUnknownPath';

export enum RoutesStatuses {
  NAME = 'NAME',
  CONTACT = 'CONTACT',
  NOTIFICATION = 'NOTIFICATION',
  BOT = 'BOT',
  PHONE = 'PHONE',
  DONE = 'DONE',
}

export const getRoutes = (answer: UserStatusResponse['data']) => {
  switch (answer) {
    case RoutesStatuses.NAME:
      return routes.PROVIDE_DATA_NAMES;
    case RoutesStatuses.CONTACT:
      return routes.PROVIDE_DATA_CONTACT_INFO;
    case RoutesStatuses.NOTIFICATION:
      return routes.NOTIFICATIONS_CHOOSE;
    case RoutesStatuses.BOT:
      return routes.VOICE_NOTIFICATIONS_CHOOSE;
    case RoutesStatuses.PHONE:
      return routes.PROVIDE_DATA_CONTACT_INFO;
    case RoutesStatuses.DONE:
      return routes.HOME;
    default:
      return checkUnknownPath();
  }
};
