import { ErrorMessage, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'src/components/common';
import { routes } from 'src/constans/routes';
import { useProvideDataContactInfoMutation } from 'src/hooks/api/useProvideData';
import { useGetChatId } from 'src/hooks/chatId';
import { checkErrorFromRequest } from 'src/utils/checkErrorFromRequest';
import {
  ButtonsContainer,
  PhoneErrorMessage,
  ProvideDataContactInfoContent,
  ProvideDataContactInfoMessage,
  ProvideDataContactInfoStyled,
  ProvideDataContactInfoTitle,
  ProvideDataContactPhone
} from 'src/pages/ProvideDataPage/style/ProvideDataContactInfo.styled';
import { Logo } from 'src/components/common/Logo/Logo';
import { getDeviceType } from 'src/utils/inspectDeviceType';

import { getValidationSchema } from './validationSchema';

export const ProvideDataContactInfo = () => {
  const navigate = useNavigate();
  const isMobile = getDeviceType() === 'mobile';
  const { currentChatId } = useGetChatId();
  const { mutateAsync: provideDataContactInfoRequest } = useProvideDataContactInfoMutation();
  const [infoMessage, setInfoMessage] = useState('');

  return (
    <ProvideDataContactInfoStyled>
      <Logo isMobile={isMobile} />
      <ProvideDataContactInfoContent>
        <ProvideDataContactInfoTitle>
          Please provide your phone number below to receive text notifications.
        </ProvideDataContactInfoTitle>
        <Formik
          initialValues={{ phone_number: '' }}
          validationSchema={getValidationSchema(false)}
          onSubmit={values =>
            provideDataContactInfoRequest({ chat_id: currentChatId, phone_number: values?.phone_number }).then(res => {
              if (res.status === 200) {
                navigate(routes.VOICE_NOTIFICATIONS_CHOOSE);
                return setInfoMessage('success');
              } else {
                setInfoMessage(res.data.message);
                return navigate(checkErrorFromRequest(res.status));
              }
            })
          }
        >
          {({ values, handleChange, errors, isValid, dirty }) => {
            return (
              <Form>
                <ProvideDataContactPhone isMobile={isMobile}>
                  <Input
                    isLarge={false}
                    name='phone_number'
                    type='text'
                    value={values.phone_number}
                    onChange={handleChange}
                    labelText='Phone number'
                    placeholder='123-456-7890'
                    errors={errors.phone_number}
                  />
                  <PhoneErrorMessage>{errors.phone_number}</PhoneErrorMessage>
                </ProvideDataContactPhone>
                <ErrorMessage name='phone_number'/>
                {!!infoMessage && <ProvideDataContactInfoMessage>{infoMessage}</ProvideDataContactInfoMessage>}
                <ButtonsContainer isMobile={isMobile}>
                  <Button className='submitButton' type='submit' disable={!(isValid && dirty)}>Continue</Button>
                  <Button className='backButton' onClick={() => navigate(routes.NOTIFICATIONS_CHOOSE)}>Back</Button>
                </ButtonsContainer>
              </Form>
            );
          }}
        </Formik>
      </ProvideDataContactInfoContent>
    </ProvideDataContactInfoStyled>
  );
};
