export function getData(url, queryParams?) {
  const newUrl = new URL(url);
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      newUrl.searchParams.append(key, String(value));
    });
  }

  return fetch(newUrl.toString(), {
    method: 'get',
    credentials: 'include',
    headers: {
      client_token: 'some_client_token',
      'Content-Type': 'application/json',
    },
  });
}
