import React, { useEffect, useState } from 'react';
import { Button, Input } from 'src/components/common';
import { QuestionTitle } from 'src/components/RiskAssessmentQuestionnaire/QuestionTitle';
import {
  UserInputButtonsFooterStyled,
  UserInputContainer,
  UserInputPageStyled,
  UserInputSelectButton,
} from 'src/pages/RiskAssessmentQuestionnairePage/UserInputPage.styled';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { UserInputCounter } from 'src/components/common/UserInputCounter';
import { createUserInputObjectRequest } from 'src/pages/RiskAssessmentQuestionnairePage/helpers/createQuantityPickerObject';
import { createUserInputsObject } from 'src/pages/RiskAssessmentQuestionnairePage/helpers/createUserInputsObject';
import { CustomTags, InputTypes, QuestionTypes } from 'src/constans/constans';

export const UserInputPage = ({
  data,
  onNext,
  chatId,
  stopPlayAndResetAudioQueue
}) => {
  const isMobile = getDeviceType() === 'mobile';
  const [userInputs, setUserInputs] = useState([]);
  const hasAdditionalDisablingCondition = data.custom_tags.some(tag => tag === CustomTags.ENABLED)
  const disableButton = () => {
    let isDisabled
    userInputs.forEach(input => {
      switch (true) {
        case input.type === InputTypes.INPUT_COUNTER:
          isDisabled = userInputs.every(input => input.value === 0)
          break
        case input.type === InputTypes.NUMBER:
        case input.type === InputTypes.STRING && !hasAdditionalDisablingCondition:
          isDisabled = userInputs.every(input => input.value === '')
          break
        default:
          isDisabled = false
      }
    })

    return isDisabled
  }
  const isCounter = userInputs.some(input => input.type === InputTypes.INPUT_COUNTER)

  useEffect(() => {
    setUserInputs(createUserInputsObject(data.user_input_payloads))
  }, [data.user_input_payloads])

  const singleSelectClickHandler = (option) => {
    const nextData = {
      question_id: data.question_id,
      chat_id: chatId,
      reply_type: QuestionTypes.SINGLE_SELECT,
      single_select_payload: {
        title: option.title,
        id: option.id
      }
    }
    stopPlayAndResetAudioQueue()
    return onNext(nextData)
  }

  const onChangeInputsValues = (value, currentInputIndex) => {
    const updatedInputs = userInputs.map((input, index) => {
      return index === currentInputIndex ? { ...input, value } : input
    })

    setUserInputs(updatedInputs)
  }

  const onChangeCounterHandler = (value, id) => {
    const updatedCounters = userInputs.map(counter => {
      return counter.id === id ? { ...counter, value } : counter
    })

    setUserInputs(updatedCounters)
  }

  const userInputClickHandler = () => {
    const nextData = {
      question_id: data.question_id,
      chat_id: chatId,
      reply_type: data.question_type,
      user_input_payloads: createUserInputObjectRequest(userInputs)
    }
    stopPlayAndResetAudioQueue()
    onNext(nextData)
  }

  const CurrentInputComponent = (userInputs) => {
    return userInputs.map((input, index) => {
      return (input.type === InputTypes.INPUT_COUNTER ?
        (
          <UserInputCounter
            key={input.id}
            value={input.value}
            labelName={input.labelName}
            maxValue={input.maxValue}
            onChange={(value) => onChangeCounterHandler(value, input.id)}
          />
        ) : (
          <Input
            key={input.id}
            type={input.type}
            isLarge={input.isLarge}
            value={userInputs[index].value}
            placeholder={input.placeholder}
            isQuestionnaireFlow={true}
            onChange={e => onChangeInputsValues(e.target.value, index)}
          />
        )
      )
    })
  }

  return (
    <>
      <UserInputPageStyled isMobile={isMobile}>
        {data.messages.map((message, index) => (
          <QuestionTitle
            key={index}
            isLastElement={index === data.messages.length - 1}
          >
            {message}
          </QuestionTitle>
        ))}
        {!!userInputs.length && <UserInputContainer isCounter={isCounter}>
          {CurrentInputComponent(userInputs)}
        </UserInputContainer>}
        {!!data.single_select_payloads.length && <UserInputSelectButton>
          {data.single_select_payloads.map((option, index) => (
            <Button
              key={index}
              onClick={() => singleSelectClickHandler(option)}
              className={option.chosen ? 'chosen' : undefined}
            >
              {option.title}
            </Button>
          ))}
        </UserInputSelectButton>}
      </UserInputPageStyled>
      <UserInputButtonsFooterStyled isMobile={isMobile}>
        <Button
          type={'submit'}
          className='submitButton'
          disable={disableButton()}
          onClick={userInputClickHandler}
        >
          Continue
        </Button>
      </UserInputButtonsFooterStyled>
    </>
  )
}
