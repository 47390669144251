import React, { useEffect, useState } from 'react';
import { ProgressBarStyled } from 'src/components/common/ProgressBar/ProgressBar.styled';

type ProgressBarProps = {
  isMobile: boolean;
  answeredBlocks: number;
  totalBlocks: number;
  hasDisabledStatus?: boolean
  isGeneralStatus?: boolean
};

export const ProgressBar = ({
  isMobile,
  answeredBlocks,
  totalBlocks,
  hasDisabledStatus,
  isGeneralStatus
}: ProgressBarProps) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress((answeredBlocks * 100) / totalBlocks);
  }, [answeredBlocks, totalBlocks]);

  return (
    <ProgressBarStyled
      isMobile={isMobile}
      hasDisabledStatus={hasDisabledStatus}
      isGeneralStatus={isGeneralStatus}
    >
      <span style={{ width: `${progress}%` }} />
    </ProgressBarStyled>
  );
};
