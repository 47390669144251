import React, { FC, MutableRefObject, useEffect, useMemo, useState } from 'react'
import ReactFlow from 'reactflow'
import { legendItems } from 'src/pages/FamilyTreePage/helpers/legendItems'
import { SvgTypes } from 'src/components/SvgTypes/SvgTypes'
import { DECEASED_AFFECTED_FEMALE } from 'src/constans/svgIcons'
import CustomNode from 'src/pages/FamilyTreePage/CustomNode/CustomNode'
import TechnicalNode from 'src/pages/FamilyTreePage/CustomNode/TechnicalNode'
import { getFamilyTreeInfo } from 'src/api/familyPedigreeQuestionnaireRequests'
import { theme } from 'src/themes/Theme'
import {
  AdditionalLegendInfo,
  AdditionalLegendPersonalInfo,
  FamilyTreeContainer,
  FamilyTreeLegend,
  FamilyTreeTitle,
  FamilyTreeWrapper,
  InfoBox,
  InfoBoxContainer,
  InfoTextBox,
  InfoTextItem,
  LegendTitle,
  SignContainer,
  SignsContainer,
  SignsList,
  SignTitle
} from 'src/pages/FamilyTreePage/FamilyTreePage.styles'
import 'src/pages/FamilyTreePage/style.css'

const nodeTypes = {
  customNode: CustomNode,
  entryNode: CustomNode,
  technicalNode: TechnicalNode
}

interface Props {
  reactFlowRef: MutableRefObject<HTMLDivElement>
  familyTreePDFProcessor: () => void
}

const edgeOptions = {
  type: 'smoothstep',
  style: {
    stroke: theme.colors.nodeContainerBorder,
    strokeWidth: 6
  }
}

const FamilyTreePage: FC<Props> = ({ reactFlowRef, familyTreePDFProcessor }) => {
  const chatId = localStorage.getItem('chatId')
  const [treeData, setTreeData] = useState(null)
  const maternalCountries = useMemo(() => treeData?.origins.maternal.countries.join(' '), [treeData?.origins.maternal.countries])
  const paternalCountries = useMemo(() => treeData?.origins.paternal.countries.join(' '), [treeData?.origins.paternal.countries])

  useEffect(() => {
    getFamilyTreeInfo(chatId).then(res => {
      setTreeData(res)
      familyTreePDFProcessor()
    })
  }, [chatId, familyTreePDFProcessor])

  return (
    <FamilyTreeWrapper ref={reactFlowRef}>
      <FamilyTreeLegend>
        <LegendTitle>Legend</LegendTitle>
        <SignsContainer>
          <SignsList>
            {legendItems.map(item => (
              <SignContainer key={item.title}>
                <SvgTypes type={item.icon} />
                <SignTitle>{item.title}</SignTitle>
              </SignContainer>
            ))}
          </SignsList>
          <AdditionalLegendInfo>
            <SvgTypes type={DECEASED_AFFECTED_FEMALE} />
            <InfoTextBox>
              <InfoTextItem>First name, Current age or age of death</InfoTextItem>
              <InfoTextItem>Relative's identified relation (if First name is unknown)</InfoTextItem>
              <InfoTextItem>Cancer diagnosis, Age of cancer diagnosis</InfoTextItem>
            </InfoTextBox>
          </AdditionalLegendInfo>
          <AdditionalLegendPersonalInfo>
            <SvgTypes type={DECEASED_AFFECTED_FEMALE} />
            <InfoTextBox>
              <InfoTextItem>Me</InfoTextItem>
              <InfoTextItem>This box represents yourself</InfoTextItem>
            </InfoTextBox>
          </AdditionalLegendPersonalInfo>
        </SignsContainer>
      </FamilyTreeLegend>

      <FamilyTreeTitle>My Cancer Family History</FamilyTreeTitle>
      <InfoBoxContainer>
        <InfoBox>
          <InfoTextItem isTitle={true}>Paternal Family</InfoTextItem>
          <InfoTextItem>Country of Origin: <span>{ paternalCountries }</span></InfoTextItem>
          <InfoTextItem>Jewish Ancestry: <span>{ treeData?.origins.paternal.ancestry }</span></InfoTextItem>
        </InfoBox>
        <InfoBox>
          <InfoTextItem isTitle={true}>Maternal Family</InfoTextItem>
          <InfoTextItem>Country of Origin: <span>{ maternalCountries }</span>
          </InfoTextItem>
          <InfoTextItem>Jewish Ancestry: <span>{ treeData?.origins.maternal.ancestry }</span></InfoTextItem>
        </InfoBox>
      </InfoBoxContainer>
      <FamilyTreeContainer>
        {(treeData?.nodes.length && treeData?.edges.length) && (
          <ReactFlow
            nodes={treeData.nodes}
            edges={treeData.edges}
            defaultEdgeOptions={edgeOptions}
            fitView={true}
            zoomOnScroll={false}
            zoomOnPinch={false}
            panOnDrag={false}
            nodeTypes={nodeTypes}
            elementsSelectable={false}
            onlyRenderVisibleElements={true}
            preventScrolling={false}
            nodesConnectable={false}
            nodesDraggable={false}
            autoPanOnConnect={false}
            minZoom={0.45}
            maxZoom={1}
          />
        )}
      </FamilyTreeContainer>
    </FamilyTreeWrapper>
  )
}

export default FamilyTreePage
