import React from 'react';
import {useSearchParams} from 'react-router-dom';
import CreatePasswordPage from 'src/pages/CreatePasswordPage';
import LoginPage from 'src/pages/Login/LoginPage';
import {LoginPageBase} from 'src/components/Routes/LoginPageBase';

export const ResetPassRoutes = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');

  if (token) {
    return (
      <LoginPageBase>
        <CreatePasswordPage/>
      </LoginPageBase>);

  }
  return (
    <LoginPageBase>
      <LoginPage/>
    </LoginPageBase>
  );
}


