import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

/**
 * This function checks or change chat id.
 * If chat id is found in local storage, it will be used as chatId.
 * @return chatId
 */

export function useGetChatId() {
  const [currentChatId, setCurrentChatId] = useLocalStorage('chatId', null);
  useEffect(() => {
    return setCurrentChatId(currentChatId);
  }, [setCurrentChatId, currentChatId]);

  return { currentChatId, setCurrentChatId };
}
