export const createUrl = (url, queryParams = {}) => {
  const newUrl = new URL(url);

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      newUrl.searchParams.append(key, String(value));
    });
  }

  return newUrl;
};
