import React from 'react';
import { Button } from 'src/components/common';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/constans/routes';
import {
  AdditionalScreenContent,
  AdditionalScreenForResetPasswordStyled,
  ButtonContainer
} from 'src/pages/ResetPasswordPage/AdditionalScreenForResetPassword.styled';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { Logo } from 'src/components/common/Logo/Logo';
import { useCompleteForgotPasswordFlow } from 'src/hooks/completeForgotPasswordFlow';

export const AdditionalScreenForResetPassword = () => {
  const isMobile = getDeviceType() === 'mobile';
  const navigate = useNavigate();
  const { setCompleteForgotPassword } = useCompleteForgotPasswordFlow();

  const onClickHandler = () => {
    setCompleteForgotPassword(false);
    navigate(routes.LOGIN);
  }

  return (
    <AdditionalScreenForResetPasswordStyled isMobile={isMobile}>
      <Logo isMobile={isMobile} />
      <AdditionalScreenContent isMobile={isMobile}>
        <p>
          Thanks! You should receive a message with a
          link to reset your password within one minute. If you do not,
          please try a different email address or phone number.
        </p>
        <ButtonContainer isMobile={isMobile}>
          <Button onClick={onClickHandler}>Back</Button>
        </ButtonContainer>
      </AdditionalScreenContent>
    </AdditionalScreenForResetPasswordStyled>
  )
}
