import { useMutation } from 'react-query';
import { provideContactInfoRequest, provideContactNamesRequest } from 'src/api/provideContactData';
import {
  ChooseNotificationRequest,
  ProvideDataContactInfoRequest,
  ProvideDataContactNamesRequest,
  VoiceChatNotificationRequest,
} from 'src/models/ProvideData';
import { chooseNotificationRequest, voiceChatNotificationRequest } from 'src/api/chooseNotificationRequest';

export const useProvideDataContactInfoMutation = () => {
  return useMutation((requestData: ProvideDataContactInfoRequest) => {
    return provideContactInfoRequest(requestData);
  });
};

export const useProvideDataContactNamesMutation = () => {
  return useMutation((requestData: ProvideDataContactNamesRequest) => {
    return provideContactNamesRequest(requestData);
  });
};

export const useChooseNotificationMutation = () => {
  return useMutation((requestData: ChooseNotificationRequest) => {
    return chooseNotificationRequest(requestData);
  });
};

export const useVoiceChatNotificationMutation = () => {
  return useMutation((requestData: VoiceChatNotificationRequest) => {
    return voiceChatNotificationRequest(requestData);
  });
};
