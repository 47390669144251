import { MultiSelectOption } from 'src/models/RiskAssessmentQuestionnaire'

export const createMultiSelectObject = (arr: MultiSelectOption[]) => {
  const arrayForMultiSelect = []
  arr.map((button) => {
    arrayForMultiSelect.push({title: button.title, id: button.id, checked: button.chosen})
  })
  return arrayForMultiSelect
}

export const createArrayForRequest = (arr) => {
  const updatedArray = []
  arr.map((oneCheckbox) => {
    oneCheckbox.checked ? updatedArray.push(oneCheckbox) : null
  })
  return updatedArray
}
