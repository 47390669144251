export const createQuantityPickersObject = (userCounters) => {
  const countersArray = []

  userCounters?.map(counter => {
    countersArray.push({
      attributeName: counter.attribute_name,
      labelName: counter.label_name,
      maxValue: counter.max_value,
      value: 0
    })
  })

  return countersArray
}

export const createUserInputObjectRequest = (array) => {
  const updatedArray = []

  array.map(counter => {
    updatedArray.push({
      id: counter.id,
      value: counter.value
    })
  })

  return updatedArray
}
