import { ResetPasswordCheckEmailRequest } from 'src/models/ResetPassword';

export const emailOrNumberFormatter = (emailOrNumber): ResetPasswordCheckEmailRequest => {
  const result = {email: '', phone: 0};
  if (emailOrNumber?.includes('@')) {
    result.email = emailOrNumber;
    delete result.phone;
  } else {
    result.phone = emailOrNumber;
    delete result.email;
  }
  return result;
}
