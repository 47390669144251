import {Navigate, useLocation} from 'react-router-dom';
import React from 'react';
import { routes } from 'src/constans/routes';
import { useGetAuthorised } from 'src/hooks/authorised';

export const LoginPageBase = ({ children }: { children: React.ReactElement }) => {
  const { isAuthorised } = useGetAuthorised();
  const location = useLocation();
  if (isAuthorised) {
    return <Navigate to={routes.HOME} replace state={{ from: location }} />;
  }

  return children;
};
