import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/common';
import { routes } from 'src/constans/routes';
import { useChooseNotificationMutation } from 'src/hooks/api/useProvideData';
import { useGetChatId } from 'src/hooks/chatId';
import { checkErrorFromRequest } from 'src/utils/checkErrorFromRequest';
import {
  ReceiveNotificationsPageContent,
  ReceiveNotificationsPageContentStyled,
  ReceiveNotificationsPageStyled,
} from 'src/pages/ReceiveNotificationsPage/ReceiveNotificationsPage.styled';
import { Logo } from 'src/components/common/Logo/Logo';
import { getDeviceType } from 'src/utils/inspectDeviceType';

export const ReceiveNotificationsPage = () => {
  const isMobile = getDeviceType() === 'mobile';
  const navigate = useNavigate();
  const { currentChatId } = useGetChatId();
  const [activeButtonIndex, setActiveButtonIndex] = useState(-1);
  const [currentNotificationType, setCurrentNotificationType] = useState('');
  const { mutateAsync: chooseNotificationRequest } = useChooseNotificationMutation();

  const clickHandler = useCallback(
    type => {
      chooseNotificationRequest({ chat_id: currentChatId, notification_preference: type }).then(res => {
        if (res.status === 200) {
          if (type === 'email') {
            navigate(routes.VOICE_NOTIFICATIONS_CHOOSE);
          } else {
            navigate(routes.PROVIDE_DATA_CONTACT_INFO);
          }
        } else {
          return navigate(checkErrorFromRequest(res.status));
        }
      });
    },
    [currentChatId, chooseNotificationRequest, navigate],
  );

  return (
    <ReceiveNotificationsPageStyled>
      <Logo isMobile={isMobile} />
      <ReceiveNotificationsPageContent isMobile={isMobile}>
        <p>How do you want to receive notifications?</p>
        <ReceiveNotificationsPageContentStyled>
          <Button
            className={activeButtonIndex === 0 ? 'active' : ''}
            onClick={() => {
              setActiveButtonIndex(0);
              setCurrentNotificationType('sms');
            }}
          >
            SMS/Text
          </Button>
          <Button
            className={activeButtonIndex === 1 ? 'active' : ''}
            onClick={() => {
              setActiveButtonIndex(1);
              setCurrentNotificationType('email');
            }}
          >
            Email
          </Button>
          <Button
            className={activeButtonIndex === 2 ? 'active' : ''}
            onClick={() => {
              setActiveButtonIndex(2);
              setCurrentNotificationType('BOTH');
            }}
          >
            Both
          </Button>
          <Button
            disable={!currentNotificationType}
            className='submitButton'
            type='submit'
            onClick={() => (clickHandler(currentNotificationType))}
          >
            Continue
          </Button>
        </ReceiveNotificationsPageContentStyled>
      </ReceiveNotificationsPageContent>
    </ReceiveNotificationsPageStyled>
  );
};
