export const routes = {
  LOGIN: '/login',
  CREATE_PASSWORD: '/create-password',
  RESET_PASSWORD: '/reset-password',
  PROVIDE_DATA_NAMES: '/provide-data-names',
  PROVIDE_DATA_CONTACT_INFO: '/provide-data-contact-info',
  NOTIFICATIONS_CHOOSE: '/notifications-chose',
  VOICE_NOTIFICATIONS_CHOOSE: '/voice-notifications-chose',
  PROVIDE_DATA_HANDLER: '/provide-data-handler',
  ADDITIONAL_SCREEN_FOR_RESET_PASSWORD: '/reset-password/notification',
  HOME: '/home',
  NEW_PASSWORD: '/new-password'
};
