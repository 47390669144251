import styled, { css } from 'styled-components';

export const QuestionTitleContainer = styled.div`
  display: flex;

  ${props => props.isLastElement && css`
    margin: ${props => (props.isMobile ? '0 0 16px' : '0 0 32px')};
  `}
`

export const QuestionTitleStyled = styled.p`
  font-family: ${props => props.theme.fonts};
  font-style: normal;
  font-weight: 400;
  font-size: ${props => (props.isMobile ? '14px' : '16px')};
  line-height: ${props => (props.isMobile ? '21px' : '24px')};
  color: ${props => props.theme.colors.additionalText};
  white-space: pre-wrap;
  margin: 0;
  align-self: center;
  overflow: hidden scroll;
  
  & b {
    font-weight: 500;
    font-size: ${props => (props.isMobile ? '16px' : '20px')};
    line-height: 24px;
  }
`
