import { useLocalStorage } from 'usehooks-ts';
import { useEffect } from 'react';
import { Tabs } from 'src/constans/constans';

export const useActiveTab = () => {
  const [activeTab, setActiveTab] = useLocalStorage('currentTab', Tabs.TAB_1);

  useEffect(() => {
    return setActiveTab(activeTab)
  }, [activeTab, setActiveTab])

  return { activeTab, setActiveTab }
}
