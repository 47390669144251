import styled from 'styled-components';

export const FamilyTreeWrapper = styled.div`
  background-color: ${props => props.theme.colors.familyTreeBackgroundColor};
  box-sizing: border-box;
  
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const FamilyTreeContainer = styled.div`
  width: 4500px;
  height: 2530px;
`

export const InfoBoxContainer = styled.div`
  display: flex;
  padding: 0 80px;
  margin-bottom: 18px;
  justify-content: space-between;
`

export const InfoBox = styled.div`
  border: 4px solid ${props => props.theme.colors.nodeContainerBorder};
  box-shadow: 0 0 2px 0 rgba(187, 196, 209, 0.8);
  border-radius: 4px;
  background: ${props => props.theme.colors.white};
  padding: 16px;
`

export const FamilyTreeTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 48px;
  color: ${props => props.theme.colors.additionalText};
  margin: 40px 80px 80px 80px;
`

export const FamilyTreeLegend = styled.div`
  border-bottom: 6px solid ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.familyTreeBackgroundColor};
  padding: 100px 0 40px;
  margin: 0 40px;
  box-sizing: border-box;
`

export const LegendTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: normal;
  margin: 0 0 40px 0;
  text-align: center;
  color: ${props => props.theme.colors.additionalText};
`

export const SignsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0 88px;
`

export const SignsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: 32px;
  gap: 48px 88px;
`

export const SignContainer = styled.li`
  display: flex;
  align-items: center;
`

export const SignTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 28px;
  color: ${props => props.theme.colors.nodeContainerBorder};
  margin-left: 10px;
`

export const AdditionalLegendInfo = styled.div`
  border: 4px solid ${props => props.theme.colors.nodeContainerBorder};
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 0 2px 0 rgba(187, 196, 209, 0.8);
  padding: 8px;
  display: flex;
  width: 206px;
  box-sizing: border-box;
  
  & svg {
    flex: 1 0 auto;
  }
`

export const AdditionalLegendPersonalInfo = styled(AdditionalLegendInfo)`
  background-color: ${props => props.theme.colors.personalCustomNodeBackgroundColor};
  border: 4px solid ${props => props.theme.colors.personalCustomNodeBackgroundColor};
  height: 180px;
`

export const InfoTextBox = styled.div`
  margin-left: 8px;
  
  & > p:first-child {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  
  & > p:nth-child(2) {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  
  & > p:last-child {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
`

export const InfoTextItem = styled.p`
  color: ${props => props.theme.colors.nodeContainerBorder};
  margin: 0;
  font-size: ${props => (props.isTitle ? 28 : 24)}px;
  font-style: italic;
  font-weight: ${props => (props.isTitle ? 700 : 400)};
  line-height: ${props => (props.isTitle ? 32 : 28)}px;
  
  & span {
    font-style: normal;
  }
`
