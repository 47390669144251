import styled from 'styled-components'

export const QuestionnaireLogoContainer = styled.div`
  width: ${props => (props.isMobile ? 28 : 46)}px;
  height: ${props => (props.isMobile ? 28 : 46)}px;;
  flex: 0 0 auto;
  margin: 0 10px 0 0;
`

export const AvatarLogo = styled.img`
  width: 100%;
  height: 100%;
`
