import styled from 'styled-components';
import { LoginPageContent } from 'src/pages/Login/LoginPage/LoginPage.style';

export const ProvideDataContactInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
  color: ${props => props.theme.colors.additionalText};

  @supports (height: 100svh) {
    min-height: 100svh;
  }

  label {
    display: inherit;
    justify-content: start;
  }

  input {
    margin-bottom: 16px;
    padding: 12px;
  }

  input::placeholder {
    padding: 12px;
  }

  .submitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    background: ${props => props.theme.colors.primary};
    width: 100%;
    max-width: 365px;
    height: 48px;
    border-radius: 16px;
    color: ${props => props.theme.colors.white};
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    
    @media(hover: hover) {
      &:hover {
        background-color: ${props => props.theme.colors.mainButtonHoverColor};
      }
    }
    
    &:active {
      background-color: ${props => props.theme.colors.mainButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
  .backButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.white};
    width: 100%;
    max-width: 365px;
    height: 48px;
    border-radius: 16px;
    color: ${props => props.theme.colors.secondary};
    border: 1px solid ${props => props.theme.colors.secondary};
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    @media(hover: hover) {
      &:hover {
        border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
        background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
        color: ${props => props.theme.colors.singleSelectButtonTextColor};
      }
    }
    
    &:active {
      border-color: ${props => props.theme.colors.ghostButtonActiveBorderColor};
      background-color: ${props => props.theme.colors.userInputBorder};
      color: ${props => props.theme.colors.ghostButtonActiveTextColor};
    }
  }
`

export const ProvideDataContactInfoContent = styled(LoginPageContent)`

`

export const ProvideDataContactInfoTitle = styled.p`
  text-align: center;
  font-family: ${props => props.theme.fonts};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 56px;
`

export const ProvideDataContactPhone = styled.div`
  position: relative;
  flex-grow: ${props => (props.isMobile ? 1 : 0)};
  
  & > div {
    margin-bottom: 40px;
  }
  
  & input {
    margin-bottom: 0;
  }
`

export const PhoneErrorMessage = styled.span`
  color: ${props => props.theme.colors.errorMessage};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  align-self: flex-start;
  position: absolute;
  top: 74px;
  left: 0;
`

export const ButtonsContainer = styled.div`

`

export const ProvideDataContactInfoMessage = styled.p`
  color: ${props => props.theme.colors.errorMessage};
  text-align: left;
  margin: 0 0 16px;
`
