import styled from 'styled-components';

export const DropdownSelectPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  max-width: 365px;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 32px;
  
  & p:nth-of-type(2) {
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 32px 0;
  }
`

export const DropdownSelectButtonsFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  
  & .submitButton {
    background-color: ${props => props.theme.colors.secondaryButtonDefaultColor};
    width: 255px;
    height: 48px;
    color: ${props => props.theme.colors.white};
    border-radius: 16px;
    border: ${props => (props.isMobile ? '1px solid' : 'none')};
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    box-shadow: 0px -4px 16px rgba(149, 169, 200, 0.15);
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.secondaryButtonHoverColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.secondaryButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
`
