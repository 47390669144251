import { useMutation } from 'react-query';
import {
  resetPasswordChangePasswordRequest,
  resetPasswordCheckEmailRequest,
  resetPasswordTokenValidationRequest,
} from 'src/api/resetPasswordRequest';
import {
  ResetPasswordChangePasswordRequest,
  ResetPasswordCheckEmailRequest,
  ResetPasswordTokenValidationRequest,
} from 'src/models/ResetPassword';

export const useResetPasswordCheckEmailMutation = () => {
  return useMutation((requestData: ResetPasswordCheckEmailRequest) => {
    return resetPasswordCheckEmailRequest(requestData);
  });
};

export const useResetPasswordTokenValidationMutation = () => {
  return useMutation((requestData: ResetPasswordTokenValidationRequest) => {
    return resetPasswordTokenValidationRequest(requestData);
  });
};

export const useResetPasswordChangePasswordMutation = () => {
  return useMutation((requestData: ResetPasswordChangePasswordRequest) => {
    return resetPasswordChangePasswordRequest(requestData);
  });
};
