import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from 'src/pages/Login/LoginPage';
import CreatePasswordPage from 'src/pages/CreatePasswordPage';
import { routes } from 'src/constans/routes';
import ResetPasswordPage from 'src/pages/ResetPasswordPage';
import ProvideDataNames from 'src/pages/ProvideDataPage';
import { ProvideDataContactInfo } from 'src/pages/ProvideDataPage/ProvideDataContactInfo';
import ReceiveNotificationsPage from 'src/pages/ReceiveNotificationsPage';
import VoiceNotificationsPage from 'src/pages/VoiceNotificationsPage';
import { HomePage } from 'src/pages/Home/HomePage';
import { ProvideDataHandler } from 'src/pages/ProvideDataPage/ProvideDataHandler';
import { AdditionalScreenForResetPassword } from 'src/pages/ResetPasswordPage/AdditionalScreenForResetPassword';
import { useGetFirstLogin } from 'src/hooks/firstLogin'
import { useCompleteForgotPasswordFlow } from 'src/hooks/completeForgotPasswordFlow';

import { UnknownRoute } from './UnknownRoute';
import { HomePageBase } from './HomePageBase';
import { LoginPageBase } from './LoginPageBase';
import { ResetPassRoutes } from './ResetPassRoutes';

export const ProjectRoutes = props => {
  const { isFirstLogin } = useGetFirstLogin();
  const { completeForgotPassword } = useCompleteForgotPasswordFlow();

  return (
    <Routes>
      <Route
        path={routes.LOGIN}
        element={
          <LoginPageBase>
            <LoginPage/>
          </LoginPageBase>
        }
      />
      <Route
        path={routes.RESET_PASSWORD}
        element={
          <LoginPageBase>
            <ResetPasswordPage />
          </LoginPageBase>
        }
      />
      {completeForgotPassword &&
        <Route
          path={routes.ADDITIONAL_SCREEN_FOR_RESET_PASSWORD}
          element={
            <LoginPageBase>
              <AdditionalScreenForResetPassword/>
            </LoginPageBase>
          }
        />
      }
      <Route
        path={routes.PROVIDE_DATA_HANDLER}
        element={
          <LoginPageBase>
            <ProvideDataHandler/>
          </LoginPageBase>
        }
      />
      {isFirstLogin &&
        <>
          <Route
            path={routes.CREATE_PASSWORD}
            element={
              <LoginPageBase>
                <CreatePasswordPage/>
              </LoginPageBase>
            }
          />
          <Route
            path={routes.PROVIDE_DATA_NAMES}
            element={
              <LoginPageBase>
                <ProvideDataNames/>
              </LoginPageBase>
            }
          />
          <Route
            path={routes.PROVIDE_DATA_CONTACT_INFO}
            element={
              <LoginPageBase>
                <ProvideDataContactInfo/>
              </LoginPageBase>
            }
          />
          <Route
            path={routes.NOTIFICATIONS_CHOOSE}
            element={
              <LoginPageBase>
                <ReceiveNotificationsPage/>
              </LoginPageBase>
            }
          />
          <Route
            path={routes.VOICE_NOTIFICATIONS_CHOOSE}
            element={
              <LoginPageBase>
                <VoiceNotificationsPage/>
              </LoginPageBase>
            }
          />
        </>
      }
      <Route
        path={routes.HOME}
        element={
          <HomePageBase>
            <HomePage />
          </HomePageBase>
        }
      />
      <Route
        path={routes.NEW_PASSWORD}
        element={<ResetPassRoutes/>}
      />
      <Route path={'*'} element={<UnknownRoute />} />
      {props.children}
    </Routes>
  );
};
