import { postRequest } from 'src/api/apiPost';
import { USER_INFO_CREDENTIALS } from 'src/config';
import { ProvideDataContactInfoRequest, ProvideDataContactNamesRequest } from 'src/models/ProvideData';

export const provideContactInfoRequest = (requestData: ProvideDataContactInfoRequest) => {
  return postRequest(USER_INFO_CREDENTIALS, requestData).then(response => {
    return response.json().then(data => ({
      data: data,
      status: response.status,
    }));
  });
};

export const provideContactNamesRequest = (requestData: ProvideDataContactNamesRequest) => {
  return postRequest(USER_INFO_CREDENTIALS, requestData).then(response => {
    return response.json().then(data => ({
      data: data,
      status: response.status,
    }));
  });
};
