import { useLocalStorage } from 'usehooks-ts';
import { useEffect } from 'react';

export function useCompleteForgotPasswordFlow() {
  const [completeForgotPassword, setCompleteForgotPassword] = useLocalStorage('completeForgotPassword', false);
  useEffect(() => {
    return setCompleteForgotPassword(completeForgotPassword);
  }, [completeForgotPassword, setCompleteForgotPassword]);

  return { completeForgotPassword, setCompleteForgotPassword };
}
