import styled from 'styled-components';
import { theme } from 'src/themes/Theme';

const progressBackground = (hasDisabledStatus, isGeneralStatus) => {
  return hasDisabledStatus ? theme.colors.white : isGeneralStatus ? theme.colors.white : theme.colors.additionalBackgroundWhite
}

const ProgressBarStyled = styled.div`
  height: ${props => (props.isMobile ? 8 : 12)}px;
  width: 100%;
  position: relative;
  background: ${props => progressBackground(props.hasDisabledStatus, props.isGeneralStatus)};
  border-radius: 5px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  margin-bottom: ${props => (props.isGeneralStatus ? props.isMobile ? 20 : 24 : props.isMobile ? 16 : 0)}px;

  & > span {
    display: block;
    height: 100%;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.additional};
    position: relative;
    overflow: hidden;
  }
`;

export { ProgressBarStyled };
