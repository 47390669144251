import { object, string } from 'yup';

export const getValidationSchema = (isFirst: boolean) => {
  return isFirst
    ? object({
      first_name: string().min(1, 'first').required('first_name'),
      last_name: string().min(1, 'last').required('last_name'),
      email: string()
        .required('email')
        .matches(
          /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/,
        ),
    })
    : object().shape(
      {
        phone_number: string().when('phone_number', val => {
          if (val?.length > 0) {
            return string()
              .required('number')
              .matches(/^([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9]{4})$/, 'Invalid phone number');
          } else {
            return string().notRequired();
          }
        }),
      },
      [
        ['phone_number', 'phone_number'],
      ],
    );
};
