import React from 'react';
import { FormikErrors } from 'formik';
import { PasswordValidationErrors } from 'src/constans/validation';
import { ErrorMessageListStyled } from 'src/components/Error/ErrorMessageList/ErrorMessageList.styled';

import { ErrorMessageItem } from '../ErrorMessageItem';

interface MyProps {
  errorMessagesList?: FormikErrors<string>;
}

const ErrorMessages = {
  [PasswordValidationErrors.Uppercase]: 'At least one capital letter',
  [PasswordValidationErrors.Lowercase]: 'At least one small letter',
  [PasswordValidationErrors.Characters]: 'At least 8 characters',
  [PasswordValidationErrors.NumberOrSymbol]: 'At least one number or symbol',
};

export const ErrorMessageList = ({ errorMessagesList }: MyProps) => {
  return (
    <ErrorMessageListStyled>
      {Object.keys(ErrorMessages).map((error, index) => {
        return (
          <ErrorMessageItem
            key={index}
            errorKey={error}
            errorMessage={ErrorMessages[error]}
            isComplete={errorMessagesList.includes(error)}
          />
        );
      })}
    </ErrorMessageListStyled>
  );
};
