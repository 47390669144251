import React, { FC } from 'react'
import { Tabs } from 'src/constans/constans';
import { CHAT_TAB_ICON, QUESTIONNAIRE_TAB_ICON } from 'src/constans/svgIcons';
import { SvgTypes } from 'src/components/SvgTypes/SvgTypes';

interface Props {
  isWidgetActiveTab: boolean
  switchTabHandler: (currentTab: Tabs) => void
}

const SwitchTabHeader: FC<Props> = ({ isWidgetActiveTab, switchTabHandler }) => {

  return (
    <ul className="nav">
      <li
        className={isWidgetActiveTab ? 'active' : undefined}
        onClick={() => switchTabHandler(Tabs.TAB_1)}
      >
        <SvgTypes type={CHAT_TAB_ICON} className='chat-icon' /> Chat
      </li>
      <li
        className={!isWidgetActiveTab ? 'active' : undefined}
        onClick={() => switchTabHandler(Tabs.TAB_2)}
      >
        <SvgTypes type={QUESTIONNAIRE_TAB_ICON} /> Questionnaire
      </li>
    </ul>
  )
}

export default SwitchTabHeader
