import React, { FC, useState } from 'react'
import Modal from 'src/components/common/Modal/Modal';

interface Props {
  onClose: (p: boolean) => void
  logOut: () => void
}

const LogOutModal: FC<Props> = ({ onClose, logOut }) => {
  const [logOutButtons] = useState([
    { title: 'Yes', className: 'submitButton', callback: logOut },
    { title: 'No', className: 'ghostButton', callback: onClose }
  ])

  return (
    <Modal
      title='Do you want to log out?'
      buttons={logOutButtons}
      onClose={onClose}
    />
  )
}

export default LogOutModal
