import React, { useEffect, useRef } from 'react';

interface MyProps {
  className?: string;
  onClick?: (p?: never) => void;
  children?: React.ReactNode | string;
  type?: 'button' | 'submit' | 'reset';
  disable?: boolean;
}

export const Button = ({ className, onClick, type, children, disable, ...props }: MyProps): JSX.Element => {
  const buttonRef = useRef(null)
  const onClickHandler = (e) => {
    e.target.blur()
    onClick && onClick()
  }

  useEffect(() => {
    (buttonRef.current as HTMLButtonElement).focus()
  }, [buttonRef]);

  return (
    <button
      ref={buttonRef}
      disabled={disable}
      type={type}
      onClick={onClickHandler}
      className={className}
      {...props}
    >
      {children}
    </button>
  );
};
