import styled from 'styled-components';

export const HomePageStyled = styled.div`
  background-color: ${props => props.theme.colors.userInputBorder};
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;

  @supports (height: 100svh) {
    min-height: 100svh;
  }
`
