import { getData } from 'src/api/apiGet';
import {
  BACK_FP,
  BACK_RA,
  GET_INFO_FP,
  GET_INFO_RA,
  GET_NEXT_QUESTION_FP,
  GET_NEXT_QUESTION_RA,
  PEDIGREE_RETURN,
  SAVE_REPLY_FP,
  SAVE_REPLY_RA,
  SUMMARY_RA
} from 'src/config';
import {
  BackAnswer,
  GeneralQuestionnaire,
  GetNextQuestion,
  SaveReplyAnswer
} from 'src/models/RiskAssessmentQuestionnaire';
import { postRequest } from 'src/api/apiPost';
import { logoutIfSessionExpired } from 'src/utils/logoutIfSessionExpired';

export const getNextQuestion = async (chatId: string, currentPage: string): Promise<GetNextQuestion> => {
  const currentUrl = currentPage === 'risk_assessment' ? GET_NEXT_QUESTION_RA : GET_NEXT_QUESTION_FP
  const result = await getData(currentUrl, { ['chatId']: chatId });
  if (result.status === 401 || result.status === 403) {
    logoutIfSessionExpired()
    return
  }
  return result.json();
};

export const saveReplyRequest = (requestData: SaveReplyAnswer, currentPage: string) => {
  const currentUrl = currentPage === 'risk_assessment' ? SAVE_REPLY_RA : SAVE_REPLY_FP
  return postRequest(currentUrl, requestData).then(response => {
    if (response.status === 401 || response.status === 403) {
      logoutIfSessionExpired()
      return
    }

    return response.json().then(data => ({
      data: data,
      status: response.status,
    }))
  });
};

export const backRequest = (requestData: BackAnswer, currentPage: string) => {
  const currentUrl = currentPage === 'risk_assessment' ? BACK_RA : BACK_FP
  return postRequest(currentUrl, requestData);
};

export const getGeneralQuestionnaireInfo = async (chatId: string, currentPage: string): Promise<GeneralQuestionnaire> => {
  const currentUrl = currentPage === 'risk_assessment' ? GET_INFO_RA : GET_INFO_FP
  const result = await getData(currentUrl, {chatId});
  return result.json();
};

export const getSummary = async (chatId: string) => {
  const result = await getData(SUMMARY_RA, { chatId })
  return result.json()
};

export const returnToLastQuestion = (requestData: { chat_id: string }) => {
  return postRequest(PEDIGREE_RETURN, requestData)
};
