import React from 'react';

import PropelLogo from './fp-blue-branding.png'

export const CatalystLogo = ({className = 'default'}) => {
  return (
    <div className={className}>
      <img
        alt='logo'
        id='image0_2785_985'
        src={PropelLogo}
      />
    </div>
  );
}
