import { createUrl } from 'src/utils/createUrl';

export function putRequest(url, data, queryParams = {}) {
  const newUrl = createUrl(url, queryParams);

  return fetch(newUrl.toString(), {
    method: 'put',
    credentials: 'include',
    headers: {
      client_token: 'some_client_token',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
