import {getData} from 'src/api/apiGet';
import {
  UpdateUserNotificationsSettingsRequest,
  UserNotificationsSettingsResponse
} from 'src/models/UserNotificationsSettings';
import {putRequest} from 'src/api/apiPut';

import {USER_NOTIFICATIONS_SETTINGS} from '../config';

export function getUserNotificationsSettings(chatId: number): Promise<UserNotificationsSettingsResponse | void> {
  return getData(USER_NOTIFICATIONS_SETTINGS, {['chatId']: chatId})
    .then(response => {
      if (response.status === 200) {
        return response.json();
      }
    })
    .then(data => {
      return data;
    })
    .catch((e) => {
      console.log('getUserNotificationsSettings error ', e)
    });
}

export const updateUserNotificationsSettings = (requestData: UpdateUserNotificationsSettingsRequest) => {
  return putRequest(USER_NOTIFICATIONS_SETTINGS, requestData).then(response => {
    return response.status == 200
  });
};
