import React from 'react';
import { CommonPageTemplate } from 'src/components/RiskAssessmentQuestionnaire/CommonPageTemplate';
import { getDeviceType } from 'src/utils/inspectDeviceType';

export const RiskAssessmentQuestionnairePage = ({
  children,
  questionnaire_progress,
  custom_tags,
  updatingAudioQueue,
  stopPlayAndResetAudioQueue,
  stopSpeakingUtterance
}) => {
  const isMobile = getDeviceType() === 'mobile';

  return (
    <>
      <CommonPageTemplate
        questionnaire_progress={questionnaire_progress}
        custom_tags={custom_tags}
        isMobile={isMobile}
        updatingAudioQueue={updatingAudioQueue}
        stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
        stopSpeakingUtterance={stopSpeakingUtterance}
      >
        {children}
      </CommonPageTemplate>
    </>
  );
};
