import { useMutation, useQuery, useQueryClient } from 'react-query';
import { debounce } from 'lodash';
import { BackAnswer, SaveReplyAnswer } from 'src/models/RiskAssessmentQuestionnaire';
import {
  backRequest,
  getGeneralQuestionnaireInfo,
  getNextQuestion,
  getSummary,
  returnToLastQuestion,
  saveReplyRequest,
} from 'src/api/riskAssessmentQuestionnaireRequests';
import { QuestionnaireStatuses, QuestionnairesTypes } from 'src/constans/constans';
import {GET_HISTORY_FP, GET_HISTORY_RA} from 'src/config';

export const useSaveReplyRequestHookQuery = (currentPage: string) => {
  const queryClient = useQueryClient();

  return useMutation((requestData: SaveReplyAnswer) => {
    return saveReplyRequest(requestData, currentPage);
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(['NextQuestion']);
    }
  });
};

export const useBackMutation = (currentPage: string) => {
  const queryClient = useQueryClient();

  return useMutation((requestData: BackAnswer) => {
    return backRequest(requestData, currentPage);
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(['NextQuestion']);
    }
  });
};

export const useReturnToLastQuestionMutation = (callback) => {

  return useMutation((requestData: { chat_id: string }) => {
    return returnToLastQuestion(requestData)
  }, { onSuccess: () => callback && callback() })
}

export const useGetNextQuestionStatusRequestHookQuery = (chatId: string, currentPage: string) => {
  const { data, isSuccess } = useQuery('NextQuestion', () => getNextQuestion(chatId, currentPage), { refetchOnWindowFocus: false });

  return { data, isSuccess };
};

export const useGetGeneralQuestionnaireInfo = (chatId: string) => {
  const { data, isSuccess, refetch } = useQuery('RiskAssessmentGeneralInfo', () => getGeneralQuestionnaireInfo(chatId, 'risk_assessment'), { refetchOnWindowFocus: false });

  return { riskAssessmentData: data, isSuccess, reFetchRiskAssessmentData: refetch };
};

export const useGeneralFamilyPedigreeInfo = (chatId: string) => {
  const { data, isSuccess, refetch } = useQuery('FamilyPedigreeGeneralInfo', () => getGeneralQuestionnaireInfo(chatId, 'family_pedigree'), { refetchOnWindowFocus: false });

  return { familyPedigreeData: data, isSuccess, reFetchFamilyPedigreeData: refetch }
};

export const useHandleGetQuestionByQuestionnaireStatus = (chatId: string, currentPage: string, status: string) => {
  let responseObject
  if (status === QuestionnaireStatuses.COMPLETED && currentPage === QuestionnairesTypes.RISK_ASSESSMENT) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    responseObject = useQuery('LastQuestion', () => getSummary(chatId), { refetchOnWindowFocus: false })
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    responseObject = useQuery('NextQuestion', () => getNextQuestion(chatId, currentPage), { refetchOnWindowFocus: false })
  }
  return responseObject
}

export const completedAssessmentHandler = debounce((chatId, questionnaireName) => {
  const currentUrl = questionnaireName === 'risk_assessment' ? GET_HISTORY_RA : GET_HISTORY_FP
  window.open(`${currentUrl}?chatId=${chatId}`, '_blank')
}, 400)
