import React from 'react';
import { Theme } from 'src/components/common';
import { ProjectRoutes } from 'src/components/Routes/ProjectRoutes';

const App = () => {
  return (
    <Theme>
      <ProjectRoutes />
    </Theme>
  );
};
export default App;
