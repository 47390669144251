import { useEffect, useRef } from 'react'

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function useClickOutside(onClose: () => void, targetRef?: any) {
  const ref = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    ref.current && (ref.current.onkeydown = handleKeyDown)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target) && onClose) {
      if (
        targetRef === undefined ||
        !targetRef?.current.contains(event.target)
      ) {
        onClose()
      }
    }
  }

  const handleKeyDown = event => {
    const currentKey = event.key.toLowerCase()
    if (currentKey === 'escape') {
      onClose()
    }
  }

  return ref
}
