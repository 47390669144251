import styled from 'styled-components';

export const SingleSelectStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
`

export const SingleSelectContentStyled = styled.div`
  width: 100%;
  max-width: 365px;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollColor};
    border-radius: 2px;
  }
`

export const SingleSelectButtonStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  button {
    width: 100%;
    max-width: 365px;
    margin: 0 auto;
    min-height: 48px;
    border-radius: 16px;
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.widgetMessageColor};
    font-size: 16px;
    color: ${props => props.theme.colors.singleSelectButtonTextColor};
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    padding: 12px;

    @media (hover: hover) {
      &:hover {
        background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
        border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
        color: ${props => props.theme.colors.singleSelectButtonTextColor};
      }
    }
    
    &:active {
      background-color: ${props => props.theme.colors.userInputBorder};
      border-color: ${props => props.theme.colors.ghostButtonActiveBorderColor};
      color: ${props => props.theme.colors.ghostButtonActiveTextColor};
    }
  }
  
  & .chosen {
    background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
    border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
    color: ${props => props.theme.colors.singleSelectButtonTextColor};
  }
`
