import { useLocalStorage } from 'usehooks-ts';
import { useEffect } from 'react';

export function useGetFirstLogin() {
  const [isFirstLogin, setIsFirstLogin] = useLocalStorage('isFirstLogin', false);
  useEffect(() => {
    return setIsFirstLogin(isFirstLogin);
  }, [isFirstLogin, setIsFirstLogin]);

  return { isFirstLogin, setIsFirstLogin };
}
