import { Form, FormikValues, FormikErrors } from 'formik';
import React, { ChangeEventHandler, useState } from 'react';
import { Input } from 'src/components/common';
import { ErrorMessageList } from 'src/components/Error';
import { Button } from 'src/components/common';
import { SHOW_HIDE_SVG } from 'src/constans/svgIcons';
import SvgTypes from 'src/components/SvgTypes';
import {
  CreatePasswordFormInputWithSvg,
  PasswordErrorMessage,
  SubmitButton
} from 'src/components/CreatePasswordForm/CreatePasswordForm.styled';
import { getDeviceType } from 'src/utils/inspectDeviceType';

interface Props {
  values: FormikValues;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  errors: FormikErrors<{ password: string; confirmPassword: string }>;
  tokenAvailable: boolean;
  isValid: boolean;
  dirty: boolean
}

export const CreatePasswordForm = ({ values, handleChange, errors, isValid }: Props) => {
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(false);
  const isMobile = getDeviceType() === 'mobile';

  return (
    <Form>
      <CreatePasswordFormInputWithSvg isMobile={isMobile}>
        <Input
          name='password'
          type={showHidePassword ? 'text' : 'password'}
          value={values.password}
          labelText='Create your password'
          onChange={handleChange}
          autoComplete='password'
          isLarge={false}
        />
        <SvgTypes onClick={() => setShowHidePassword(!showHidePassword)} type={SHOW_HIDE_SVG} />
      </CreatePasswordFormInputWithSvg>
      {errors.password && <ErrorMessageList errorMessagesList={errors.password} />}
      <CreatePasswordFormInputWithSvg
        isMobile={isMobile}
        isLastItem={true}
      >
        <Input
          name='confirmPassword'
          type={showHideConfirmPassword ? 'text' : 'password'}
          value={values.confirmPassword}
          labelText='Re-enter your password'
          onChange={handleChange}
          autoComplete='confirmPassword'
          errors={errors.confirmPassword}
          isLarge={false}
        />
        <SvgTypes onClick={() => setShowHideConfirmPassword(!showHideConfirmPassword)} type={SHOW_HIDE_SVG} />
        <PasswordErrorMessage>{errors.confirmPassword}</PasswordErrorMessage>
      </CreatePasswordFormInputWithSvg>
      <SubmitButton isMobile={isMobile}>
        <Button
          type='submit'
          className='submit'
          disable={!(isValid && values.password === values.confirmPassword && values.password)}
        >
          Create password
        </Button>
      </SubmitButton>
    </Form>
  );
};
