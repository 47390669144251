import { postRequest } from 'src/api/apiPost';
import { LOGIN } from 'src/config';
import { LoginRequest } from 'src/models/Login';

export const loginRequest = (requestData: LoginRequest) => {
  return postRequest(LOGIN, requestData).then(response => {
    return response.json().then(data => ({
      data: data,
      status: response.status,
    }));
  });
};
