import { getData } from 'src/api/apiGet';
import { GET_PERSISTENT_MENU } from 'src/config';

export interface CallToActionsInterface {
  enableMessengerExtensions: boolean
  enabled: boolean
  completed: boolean
  payload: string
  title: string
  type: string
  url: string
  webViewHeightRatio: string
}

interface GetPersistentMenuResponseInterface {
  botId: number
  callToActions: CallToActionsInterface[]
  composerInputDisabled: boolean
  id: string
  locale: string
}

export const getPersistentMenu = async (chatId: string): Promise<GetPersistentMenuResponseInterface> => {
  try {
    const result = await getData(GET_PERSISTENT_MENU, { chatId })
    if (result.ok) {
      return result.json()
    }
  } catch (e) {
    console.log(e)
  }
}
