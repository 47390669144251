import styled from 'styled-components';

export const MultiSelectPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 365px;
  flex-grow: 1;
  height: calc(100% - 80px);
`

export const MultiSelectPageCheckboxesStyled = styled.div`
  width: 100%;
  max-width: 365px;
  overflow: hidden scroll;
  margin-bottom: 24px;
  flex-grow: 1;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollColor};
    border-radius: 2px;
  }
`

export const MultiSelectPageFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
  .submitButton {
    background-color: ${props => props.theme.colors.secondaryButtonDefaultColor};
    width: 256px;
    height: 48px;
    color: ${props => props.theme.colors.white};
    border-radius: 16px;
    border: ${props => (props.isMobile ? '1px solid' : 'none')};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    box-shadow: 0px -4px 16px rgba(149, 169, 200, 0.15);
    cursor: pointer;
    
    &:hover {
      background-color: ${props => props.theme.colors.secondaryButtonHoverColor};
    }
    
    &:active {
      background-color: ${props => props.theme.colors.secondaryButtonFocusColor};
    }
    
    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
`
