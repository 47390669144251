import styled from 'styled-components';

import { LoginPageContent } from '../Login/LoginPage/LoginPage.style';

export const AdditionalScreenForResetPasswordStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
  min-height: 100vh;

  @supports (height: 100svh) {
    min-height: 100svh;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.white};
    width: 100%;
    max-width: 365px;
    height: 48px;
    border-radius: 16px;
    color: ${props => props.theme.colors.secondary};
    border: 1px solid ${props => props.theme.colors.secondary};
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
        background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
        color: ${props => props.theme.colors.singleSelectButtonTextColor};
      }
    }
    
    &:active {
      border-color: ${props => props.theme.colors.ghostButtonActiveBorderColor};
      background-color: ${props => props.theme.colors.userInputBorder};
      color: ${props => props.theme.colors.ghostButtonActiveTextColor};
    }
  }
  p {
    text-align: center;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.colors.additionalText};
    margin-bottom: 56px;
  }
`;

export const AdditionalScreenContent = styled(LoginPageContent)`
  & p {
    flex-grow: ${props => (props.isMobile ? 1 : 0)};
  }
`

export const ButtonContainer = styled.div`
  margin-bottom: 24px;
`
