import styled from 'styled-components';

export const CloseButtonStyled = styled.div`
  margin-left: 24px;
  
  button {
    background-color: ${props => props.theme.colors.white};
    border: none;
    cursor: pointer;
    padding: 0;
    display: block;
  }
  
  & svg {
    display: block;
  }
`;
