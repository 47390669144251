import styled from 'styled-components';
import { LoginPageContent } from 'src/pages/Login/LoginPage/LoginPage.style';

export const VoiceNotificationsPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
  color: ${props => props.theme.colors.additionalText};

  @supports (height: 100svh) {
    min-height: 100svh;
  }
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 365px;
    height: 48px;
    border-radius: 16px;
    color: ${props => props.theme.colors.white};
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    :disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
    }
  }
  
  .agreeButton {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    margin-bottom: 16px;
    
    @media(hover: hover) {
      &:hover {
        background-color: ${props => props.theme.colors.mainButtonHoverColor};
      }
    }

    &:active {
      background-color: ${props => props.theme.colors.mainButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
  
  .disagreeButton {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.secondary};
    border: 1px solid ${props => props.theme.colors.secondary};

    @media(hover: hover) {
      &:hover {
        border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
        background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
        color: ${props => props.theme.colors.singleSelectButtonTextColor};
      }
    }

    &:active {
      border-color: ${props => props.theme.colors.ghostButtonActiveBorderColor};
      background-color: ${props => props.theme.colors.userInputBorder};
      color: ${props => props.theme.colors.ghostButtonActiveTextColor};
    }
  }
`;

export const VoiceNotificationsPageContent = styled(LoginPageContent)`
  & p {
    text-align: center;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 8px;

    &:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 56px;
      flex-grow: ${props => (props.isMobile ? 1 : 0)};
    }
  }
`

export const ButtonsContainer = styled.div`
  margin-bottom: 24px;
`
