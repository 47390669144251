import styled from 'styled-components';

import { SingleSelectButtonStyled } from './SingleSelect.styled';

export const UserInputPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex: 1 1 auto;
  height: calc(100% - 162px);
  width: 100%;
  max-width: 365px;
  margin-bottom: 24px;

  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollColor};
    border-radius: 2px;
  }
  textarea {
    width: ${props => (props.isMobile ? '100%' : '365px')};
    height: ${props => (props.isMobile ? 96 : 112)}px;
    border-radius: 8px;
    border: 1px solid ${props => (props.theme.colors.userInputBorder)};
    padding: 12px;
    font-family: ${props => props.theme.fonts};
    resize: none;
    box-sizing: border-box;
  }
  
  input {
    border: ${props => (`1px solid ${props.theme.colors.userInputBorder}`)};
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    outline: none;
  }
`

export const UserInputContainer = styled.div`
  width: ${props => (props.isCounter ? '250px' : '100%')};
  margin: 0 0 10px 0;
  overflow: hidden auto;
`

export const UserInputSelectButton = styled(SingleSelectButtonStyled)`
  width: 256px;
`

export const UserInputButtonsFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;

  .submitButton {
    background-color: ${props => props.theme.colors.secondaryButtonDefaultColor};
    width: 256px;
    height: 48px;
    color: ${props => props.theme.colors.white};
    border-radius: 16px;
    border: ${props => (props.isMobile ? '1px solid' : 'none')};
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    box-shadow: 0px -4px 16px rgba(149, 169, 200, 0.15);
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.secondaryButtonHoverColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.secondaryButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
`
