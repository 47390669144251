import styled from 'styled-components';

import { LoginPageContent } from '../Login/LoginPage/LoginPage.style';

export const ResetPasswordPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
  width: 100%;
  min-height: 100vh;

  @supports (height: 100svh) {
    min-height: 100svh;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.primary};
    width: 100%;
    max-width: 365px;
    height: 48px;
    border-radius: 16px;
    color: ${props => props.theme.colors.white};
    border: none;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.mainButtonHoverColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.mainButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
  
  label {
    display: inherit;
    justify-content: start;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.additionalText};
  }

  input {
    margin-bottom: 16px;
    padding: 12px;
  }

  input::placeholder {
    padding: 12px;
  }
  
  h2 {
    text-align: center;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${props => props.theme.colors.additionalText};
    margin: 0 0 8px;
  }
  p {
    text-align: center;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.colors.additionalText};
    margin: 0 0 32px;
  }
`

export const ResetPasswordPageContent = styled(LoginPageContent)`
  & form > div:first-child {
    flex-grow: ${props => (props.isMobile ? 1 : 0)};
    justify-content: flex-start;
  }
`

export const SubmitButton = styled.div`

`
