import { FAMILY_TREE_FILE } from 'src/config'

type FamilyTreeResponse = {
  url: string
}

export const sendFamilyTreeFile = async (chatId: string, file: FormData): Promise<FamilyTreeResponse> => {
  try {
    const res = await fetch(`${FAMILY_TREE_FILE}?chatId=${chatId}`, {
      credentials: 'include',
      method: 'POST',
      body: file
    })
    if (res.ok) {
      return res.json()
    }
  } catch (e) {
    console.log(e)
  }
}
