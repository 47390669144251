import styled from 'styled-components';

const InputStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 8px;
  margin-bottom: ${props => (props.isLastElement ? 0 : props.isLarge ? 16 : 10)}px;
  position: relative;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  input {
    border-radius: 8px;
    border: none;
    box-shadow: ${props => (props.errors ? `inset 0 0 0 2px ${props.theme.colors.errorMessage}` : 'none')};
    background: ${props => props.theme.colors.white};
    box-sizing: border-box;
    padding: 12px;
    
    &::placeholder {
      color: ${props => (props.theme.colors.userInputPlaceholder)};
      font-family: ${props => (props.theme.fonts)};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export { InputStyled };
