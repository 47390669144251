import React from 'react';
import { Button } from 'src/components/common';
import { Checkbox } from 'src/components/common/Checkbox';
import {
  MultiSelectChoiceButtonStyled
} from 'src/components/RiskAssessmentQuestionnaire/style/MultiSelectChoiceButton.styled';

type MultiSelectChoiceButtonProps = {
  checked: boolean;
  label: string;
  onChange: () => void;
};

export const MultiSelectChoiceButton = ({ label, onChange, checked, ...props }: MultiSelectChoiceButtonProps) => {
  return (
    <MultiSelectChoiceButtonStyled>
      <Button type={'button'}>
        <Checkbox label={label} checked={checked} onChange={onChange} {...props} />
      </Button>
    </MultiSelectChoiceButtonStyled>
  );
};
