import React from 'react';
import { Button } from 'src/components/common';
import SvgTypes from 'src/components/SvgTypes';
import { CLOSE, CLOSE_MOBILE } from 'src/constans/svgIcons';
import { CloseButtonStyled } from 'src/components/RiskAssessmentQuestionnaire/style/CloseButton.styled';

type CloseButtonProps = {
  onClick: () => void;
  isMobile: boolean;
};

export const CloseButton = ({ onClick, isMobile }: CloseButtonProps) => {
  return (
    <CloseButtonStyled>
      <Button onClick={onClick}>
        <SvgTypes type={isMobile ? CLOSE_MOBILE : CLOSE} />
      </Button>
    </CloseButtonStyled>
  );
};
