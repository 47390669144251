import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import { Button } from 'src/components/common';
import {
  UserInputCounterStyled,
  CounterButtonsContainer,
  CounterValue
} from 'src/components/RiskAssessmentQuestionnaire/UserInputCounter.styled';

type UserInputCounterProps = {
  value: number
  labelName: string
  onChange: (p: number) => void
  maxValue: number
};

export const UserInputCounter = ({ value, labelName, onChange, maxValue = 99 }: UserInputCounterProps) => {
  const [currentNumber, setCurrentNumber] = useState(value || 0);
  const stateRef: MutableRefObject<number> = useRef();
  const [disableMinCounter, setDisableMinCounter] = useState(false);
  const [disableMaxCounter, setDisableMaxCounter] = useState(false);
  stateRef.current = currentNumber

  useEffect(() => {
    setDisableMinCounter(currentNumber < 1)
    setDisableMaxCounter(currentNumber === maxValue)
  }, [currentNumber, maxValue]);

  const decrementValue = () => {
    setCurrentNumber(prevState => --prevState)
    setTimeout(() => onChange(stateRef.current), 0)
  }

  const incrementValue = () => {
    setCurrentNumber(prevState => ++prevState)
    setTimeout(() => onChange(stateRef.current), 0)
  }

  return (
    <UserInputCounterStyled>
      <p>{labelName}</p>
      <CounterButtonsContainer>
        <Button onClick={decrementValue} disable={disableMinCounter}>
          -
        </Button>
        <CounterValue>{currentNumber}</CounterValue>
        <Button onClick={incrementValue} disable={disableMaxCounter}>
          +
        </Button>
      </CounterButtonsContainer>
    </UserInputCounterStyled>
  );
};
