import { useLocalStorage } from 'usehooks-ts';
import { useEffect } from 'react';

export function useGetAuthorised() {
  const [isAuthorised, setIsAuthorised] = useLocalStorage('isAuthorised', false);
  useEffect(() => {
    return setIsAuthorised(isAuthorised);
  }, [isAuthorised, setIsAuthorised]);

  return { isAuthorised, setIsAuthorised };
}
