import styled from 'styled-components';

export const GeneralInfoContainer = styled.div`
  background-color: ${props => (props.hasDisabledStatus ? props.theme.colors.blockedGeneralInfoColor : props.theme.colors.additionalWidgetMessageColor)};
  border-radius: 15px;
  margin-bottom: ${props => (props.isMobile ? 16 : 24)}px;
  padding: 16px 24px;
  
  &:nth-child(2) {
    margin-bottom: 0;
  }
  
  & button {
    background-color: ${props => props.theme.colors.secondaryButtonDefaultColor};
    color: ${props => props.theme.colors.white};
    min-width: ${props => (props.isMobile ? '100%' : '240px')};
    height: 48px;
    padding: ${props => (props.isMobile ? '12px 10px' : '12px 16px')};
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: ${props => (props.isMobile ? '0 0 6px 0' : '0 8px 0 0')};

    &:hover {
      background-color: ${props => props.theme.colors.secondaryButtonHoverColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.secondaryButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  & .ghost-button {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.secondary};
    border: 1px solid ${props => props.theme.colors.secondary};
    
    &:hover {
      background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
      border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
      color: ${props => props.theme.colors.singleSelectButtonTextColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.userInputBorder};
      border-color: ${props => props.theme.colors.ghostButtonActiveBorderColor};
      color: ${props => props.theme.colors.ghostButtonActiveTextColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      color: ${props => props.theme.colors.white};
      cursor: auto;
      border: none;
    }
    
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;
`

export const GeneralInfoTitle = styled.p`
  color: ${props => (props.hasDisabledStatus ? props.theme.colors.additionalGray : props.theme.colors.additionalText)};
  margin: 0 0 16px 0;
  font-family: ${props => props.theme.fonts};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`
