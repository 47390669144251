import React, { InputHTMLAttributes, ReactNode, useMemo } from 'react';
import { InputTypes } from 'src/constans/constans';

import * as S from './Input.styled';

interface MyProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  isLarge?: boolean;
  isLastElement?: boolean
  errors?: string
  type?: string
  isQuestionnaireFlow?: boolean
  children?: ReactNode
}

export const Input: React.FunctionComponent<MyProps> = ({
  labelText,
  isLarge,
  isLastElement,
  errors,
  type = 'text',
  isQuestionnaireFlow,
  children,
  ...props }): JSX.Element => {
  const isNumericInput = type === InputTypes.NUMBER
  const keyDownHandler = (e) => {
    const pattern = /^[0-9]*$/
    const valueLength = e.target.value.length
    const keyCode = e.keyCode
    const key = e.key
    const isPressedLikeNumbersSymbols = keyCode === 8 || pattern.test(key)
    if (type !== InputTypes.NUMBER) return
    if ((valueLength === 3 && keyCode !== 8) || (!isPressedLikeNumbersSymbols)) e.preventDefault()
  }

  const wheelHandler = (e) => e.target.blur()
  const isTextArea = useMemo(() => (isLarge ? isLarge : !!isLarge), [isLarge])

  return (
    <S.InputStyled
      isLastElement={isLastElement}
      errors={errors}
      isQuestionnaireFlow={isQuestionnaireFlow}
      isLarge={isLarge}
    >
      {labelText ? <label>{labelText}</label> : null}
      {isTextArea ? <textarea {...props} /> : <input type={type.toLowerCase()} inputMode={isNumericInput ? 'numeric' : 'text'} onKeyDown={keyDownHandler} onWheel={wheelHandler} {...props}/>}
      { children }
    </S.InputStyled>
  );
};
