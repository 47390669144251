import React from 'react';
import SvgTypes from 'src/components/SvgTypes';
import { CHECK_ICON_SVG, WRONG_ICON_SVG } from 'src/constans/svgIcons';
import { DataAutotestAttributes } from 'src/constans/dataAutotestAttributes';

import * as S from './ErrorMessageItem.styled';

interface MyProps {
  errorKey: string;
  isComplete?: boolean;
  errorMessage?: string;
}

export const ErrorMessageItem = ({ errorKey, isComplete, errorMessage }: MyProps) => {
  const dataAutotestAttribute = (isComplete) => `create-${isComplete ? 'x' : 'check'}-${DataAutotestAttributes[errorKey]}`

  return (
    <S.ErrorMessageItemStyled>
      {<SvgTypes
        dataAutotest={dataAutotestAttribute(isComplete)}
        type={isComplete ? WRONG_ICON_SVG : CHECK_ICON_SVG}
      />}
      <p>{errorMessage}</p>
    </S.ErrorMessageItemStyled>
  );
};
