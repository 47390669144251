import { useLocalStorage } from 'usehooks-ts';
import { useEffect } from 'react';

export function useUserName() {
  const [userName, setUserName] = useLocalStorage('userName', '');
  useEffect(() => {
    return setUserName(userName);
  }, [userName, setUserName]);

  return { userName, setUserName };
}
