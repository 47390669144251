import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { QuestionTitle } from 'src/components/RiskAssessmentQuestionnaire/QuestionTitle';
import { Button } from 'src/components/common';
import { CustomTags, QuestionnairesTypes } from 'src/constans/constans';
import {
  SingleSelectButtonStyled,
  SingleSelectStyled,
  SingleSelectContentStyled
} from 'src/pages/RiskAssessmentQuestionnairePage/SingleSelect.styled';

export const SingleSelect = ({
  data,
  onNext,
  chatId,
  setFamilyTreeSettings,
  stopPlayAndResetAudioQueue
}) => {
  const isMobile = getDeviceType() === 'mobile';
  const hasReturnTag = data.custom_tags.some(tag => tag === CustomTags.RETURN);
  const hasSubmitTag = data.custom_tags.some(tag => tag === CustomTags.SUBMIT);
  const hasAvatarLogo = data.custom_tags.some(tag => tag === CustomTags.AFFIRMATION);
  const [currentPage, setCurrentPage] = useLocalStorage('currentQuestionnairePage', '');
  const isFamilyTreeSetup = hasSubmitTag && currentPage === QuestionnairesTypes.FAMILY_PEDIGREE

  const singleSelectClickHandler = (button) => {
    const nextData = {
      question_id: data.question_id,
      chat_id: chatId,
      reply_type: data.question_type,
      single_select_payload: {
        title: button.title,
        id: button.id
      }
    }
    stopPlayAndResetAudioQueue()
    onNext(nextData)
    if (hasReturnTag) {
      setCurrentPage(QuestionnairesTypes.ALL_QUESTIONNAIRES)
    }
    isFamilyTreeSetup && setFamilyTreeSettings({
      isOpen: true,
      isLoading: true
    })
  }

  return <SingleSelectStyled isMobile={isMobile}>
    <SingleSelectContentStyled isMobile={isMobile}>
      {data.messages.map((message, index) => (
        <QuestionTitle
          key={index}
          index={index}
          isLastElement={index === data.messages.length - 1}
          hasAvatarLogo={hasAvatarLogo}
        >
          {message}
        </QuestionTitle>
      ))}
      <SingleSelectButtonStyled isMobile={isMobile}>
        {data.single_select_payloads.map((button, index) => {
          return <Button key={index} onClick={() => singleSelectClickHandler(button)} className={button.chosen ? 'chosen' : undefined}>
            {button.title}
          </Button>;
        })}
      </SingleSelectButtonStyled>
    </SingleSelectContentStyled>
  </SingleSelectStyled>
}
