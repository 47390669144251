import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { routes } from 'src/constans/routes';
import { useLogoutMutation } from 'src/hooks/api/useLogin';
import { useGetAuthorised } from 'src/hooks/authorised';
import { useGetLoginFinish } from 'src/hooks/loginFinish';
import { useGetChatId } from 'src/hooks/chatId';
import { useUserName } from 'src/hooks/userName';
import { SwitchTab } from 'src/pages/SwitchTab/SwitchTab';
import { getStatusForProvideData } from 'src/api/getStatusForProvideData';
import { useGetFirstLogin } from 'src/hooks/firstLogin';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { useActiveTab } from 'src/hooks/useActiveTab';
import { useGetVoiceChatInfo } from 'src/hooks/api/useVoiceChat';
import { BotTypePreference, PostMessagesTypes, QuestionnairesTypes, Tabs } from 'src/constans/constans';
import Header from 'src/pages/Header/Header';
import VoiceButton from 'src/components/VoiceButton/VoiceButton';
import LogOutModal from 'src/pages/Home/components/LogOutModal/LogOutModal';
import NotificationModal from 'src/pages/Home/components/NotificationModal/NotificationModal';

import {
  HomePageStyled
} from './HomePage.styled';

export const HomePage = () => {
  const isMobile = getDeviceType() === 'mobile';
  const navigate = useNavigate();
  const { mutateAsync: logoutRequest } = useLogoutMutation();
  const { setIsAuthorised } = useGetAuthorised();
  const { setIsLoginFinish } = useGetLoginFinish();
  const { setIsFirstLogin } = useGetFirstLogin();
  const { setUserName } = useUserName();
  const { currentChatId, setCurrentChatId } = useGetChatId();
  const { activeTab, setActiveTab } = useActiveTab();
  const { data } = useGetVoiceChatInfo(currentChatId);
  const [, setCurrentPage] = useLocalStorage('currentQuestionnairePage', QuestionnairesTypes.ALL_QUESTIONNAIRES);
  const [isShowLogOutModal, setIsShowLogOutModal] = useState(false);
  const [isShowNotificationModal, setIsShowNotificationModal] = useState(false);
  const [isShowVoiceButton, setIsShowVoiceButton] = useState(false);
  const [widgetSource, setWidgetSource] = useState(null);
  const [botTypePreference, setBotTypePreference] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isShowTooltip, setIsShowTooltip] = useState(false)
  const [audioQueue, setAudioQueue] = useState<HTMLAudioElement[]>([])
  const [isWidgetStartedPlay, setIsWidgetStartedPlay] = useState(false)
  const [isWidgetFinishedPlay, setIsWidgetFinishedPlay] = useState(true)

  useEffect(() => {
    getStatusForProvideData(currentChatId).then(res => {
      if (res.status === 401 || res.status === 403) {
        logOut();
      }
    })
  }, [currentChatId, activeTab])

  useEffect(() => {
    if (data) {
      setBotTypePreference(data.bot_type_preference)
    }
  }, [data]);

  useEffect(() => {
    const handleBlur = () => {
      isMenuOpen && setIsMenuOpen(false)
      isShowTooltip && setIsShowTooltip(false)
    }
    document.addEventListener('visibilitychange', visibilityChangeListener)
    window.addEventListener('blur', handleBlur)

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeListener)
      window.removeEventListener('blur', handleBlur)
    }
  }, [isMenuOpen, isShowTooltip]);

  useEffect(() => {
    if (audioQueue.length && botTypePreference === BotTypePreference.VOICE) {
      audioQueue[0].volume = 0.3
      audioQueue[0].play().catch(err => {
        console.log(err)
      })

      audioQueue[0].onended = () => {
        setAudioQueue(audioQueue.slice(1))
      }
    }
  }, [audioQueue]);

  const stopPlayAndResetAudioQueue = () => {
    audioQueue.length && audioQueue[0].pause()
    setAudioQueue([])
  }

  const updatingAudioQueue = (data: string) => {
    const audio = new Audio(`data:audio/mp3;base64,${data}`)
    setAudioQueue(prevState => ([
      ...prevState,
      audio
    ]))
  }

  const visibilityChangeListener = () => {
    const isHiddenVisibility = document.visibilityState === 'hidden'
    if (isHiddenVisibility) {
      stopPlayAndResetAudioQueue()
      stopSpeakingUtterance()
    }
  }

  const stopSpeakingUtterance = () => {
    widgetSource?.postMessage({
      type: PostMessagesTypes.WIDGET_CANCEL_VOICE_SPEAKING
    }, '*')
  }

  const logOut = () => {
    logoutRequest().then(res => {
      res.status === 200 && navigate(routes.LOGIN);
      stopPlayAndResetAudioQueue()
      stopSpeakingUtterance()
      resetLocalStorage();
    });
  };

  const resetLocalStorage = () => {
    setIsAuthorised(false);
    setIsLoginFinish(false);
    setIsFirstLogin(false);
    setUserName('');
    setCurrentChatId(null);
    setActiveTab(Tabs.TAB_1);
    setCurrentPage(QuestionnairesTypes.ALL_QUESTIONNAIRES);
  }

  return (
    <HomePageStyled isMobile={isMobile}>
      {botTypePreference && (
        <>
          <Header
            setIsShowLogOutModal={setIsShowLogOutModal}
            setIsShowNotificationModal={setIsShowNotificationModal}
            botTypePreference={botTypePreference}
            setBotTypePreference={setBotTypePreference}
            widgetSource={widgetSource}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            logOut={logOut}
            activeTab={activeTab}
            updatingAudioQueue={updatingAudioQueue}
            stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
            isShowTooltip={isShowTooltip}
            setIsShowTooltip={setIsShowTooltip}
          />
          <SwitchTab
            widgetSource={widgetSource}
            setWidgetSource={setWidgetSource}
            setIsShowVoiceButton={setIsShowVoiceButton}
            botTypePreference={botTypePreference}
            logOut={logOut}
            updatingAudioQueue={updatingAudioQueue}
            stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
            stopSpeakingUtterance={stopSpeakingUtterance}
            setIsWidgetStartedPlay={setIsWidgetStartedPlay}
            setIsWidgetFinishedPlay={setIsWidgetFinishedPlay}
            isWidgetFinishedPlay={isWidgetFinishedPlay}
            isWidgetStartedPlay={isWidgetStartedPlay}
          />
          {isShowLogOutModal && (
            <LogOutModal
              onClose={setIsShowLogOutModal}
              logOut={logOut}
            />
          )}
          {isShowNotificationModal && (
            <NotificationModal
              onClose={setIsShowNotificationModal}
              currentChatId={currentChatId}
            />
          )}
          {isShowVoiceButton && <VoiceButton
            isMobile={isMobile}
            setIsShowVoiceButton={setIsShowVoiceButton}
            widgetSource={widgetSource}
          />}
        </>
      )}
    </HomePageStyled>
  )
}
