import { getData } from 'src/api/apiGet'
import { GET_FAMILY_TREE_INFO } from 'src/config'

export type TreeEdge = {
  id: string
  source: string
  sourceHandle: string
  target: string
  targetHandle: string
}

export type TreeNode = {
  data?: {
    diagnoses?: string
    relation?: string
    relativeInfo: string
    status: string
  }
  id: string
  position: {
    x: number
    y: number
  }
  type: string
}

export type TreeOrigins = {
  maternal: {
    countries: string[],
    ancestry: string
  },
  paternal: {
    countries: string[],
    ancestry: string
  }
}

type FamilyTreeType = {
  edges: TreeEdge[]
  nodes: TreeNode[]
  origins: TreeOrigins
}

export const getFamilyTreeInfo = async (chatId: string): Promise<FamilyTreeType> => {
  try {
    const result = await getData(GET_FAMILY_TREE_INFO, {chatId})
    if (result.ok) {
      return result.json()
    }
  } catch (e) {
    console.log(e)
  }
}
