import { useMutation } from 'react-query';
import { createPassRequest } from 'src/api/createPassRequest';
import { firstLoginRequest } from 'src/api/firstLoginRequest';
import { loginRequest } from 'src/api/loginRequest';
import { CreatePasswordRequest, FirstLoginRequest, LoginRequest } from 'src/models/Login';
import { logoutRequest } from 'src/api/logoutRequest';

export const useCreatePasswordMutation = () => {
  return useMutation((requestData: CreatePasswordRequest) => {
    return createPassRequest(requestData);
  });
};

export const useFirstLoginMutation = () => {
  return useMutation((requestData: FirstLoginRequest) => {
    return firstLoginRequest(requestData);
  });
};

export const useLoginMutation = () => {
  return useMutation((requestData: LoginRequest) => {
    return loginRequest(requestData);
  });
};

export const useLogoutMutation = () => {
  return useMutation(() => {
    return logoutRequest();
  });
};
