import styled from 'styled-components';

export const BackButtonStyled = styled.div`
  min-width: ${props => (props.isMobile ? 24 : 32)}px;
  margin-right: 24px;
  
  button {
    display: block;
    background-color: ${props => props.theme.colors.white};
    border: none;
    padding: 0;
    cursor: ${props => (props.isLoading ? 'auto' : 'pointer')};
  }
  
  & svg {
    & path {
      stroke: ${props => props.isLoading && props.theme.colors.additionalInactive};
    }
  }
`;
