import React, { ReactNode } from 'react';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { Button } from 'src/components/common';

import {
  ButtonContainer,
  ModalContainer,
  Title,
  ModalWrapper,
  Body
} from './Modal.styles';

interface ButtonInterface {
  title: string
  className: string
  callback: (p?: boolean) => void
  disable?: boolean
}

interface MyProps {
  children?: ReactNode
  title: string
  buttons: ButtonInterface[]
  onClose: (p: boolean) => void
}

const Modal = ({
  children,
  title,
  buttons,
  onClose
}: MyProps): JSX.Element => {
  const handleClose = () => onClose(false)
  const ref = useClickOutside(handleClose)

  return (
    <ModalWrapper>
      <ModalContainer ref={ref}>
        <Title>
          { title }
        </Title>
        {children && (
          <Body>
            { children }
          </Body>
        )}
        <ButtonContainer>
          {buttons.map((button, index) => (
            <Button
              key={index}
              className={button.className}
              onClick={button.callback}
              disable={button.disable}
            >
              { button.title }
            </Button>
          ))}
        </ButtonContainer>
      </ModalContainer>
    </ModalWrapper>
  )
}

export default Modal
