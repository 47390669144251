import React from 'react';
import { useGetAuthorised } from 'src/hooks/authorised';
import { Navigate, useLocation } from 'react-router-dom';
import { routes } from 'src/constans/routes';

export const HomePageBase = ({ children }: { children: React.ReactElement }) => {
  const { isAuthorised } = useGetAuthorised();
  const location = useLocation();

  if (!isAuthorised) {
    return <Navigate to={routes.LOGIN} replace state={{ from: location }} />;
  }
  return children
};
