import { logoutRequest } from 'src/api/logoutRequest';
import { SERVER_URL } from 'src/config';

export const logoutIfSessionExpired = () => {
  logoutRequest()
  window.localStorage.setItem('isAuthorised', 'false')
  window.localStorage.setItem('isLoginFinish', 'false')
  window.localStorage.setItem('userName', '')
  window.localStorage.setItem('chatId', 'null')
  window.location.href = `${SERVER_URL}/login`
}
