import { InputTypes } from 'src/constans/constans';

export const createUserInputsObject = (userInputs) => {
  const userInputsArray = []

  userInputs?.map(input => {
    switch (input.type) {
      case InputTypes.INPUT_COUNTER:
        userInputsArray.push({
          id: input.id,
          attributeName: input.attribute_name,
          labelName: input.label_text,
          maxValue: input.max_value,
          type: input.type,
          value: +input.chosen || 0
        })
        break
      default:
        userInputsArray.push({
          id: input.id,
          value: input.chosen || '',
          attributeName: input.attribute_name,
          placeholder: input.placeholder,
          type: input.type,
          isLarge: input.is_large
        })
    }
  })

  return userInputsArray
}
