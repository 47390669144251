import React, { FC, useMemo } from 'react'
import { Handle, Position } from 'reactflow'
import { SvgTypes } from 'src/components/SvgTypes/SvgTypes'
import {
  diagnosesFontStyleHandler,
  relativeInfoFontStyleHandler
} from 'src/pages/FamilyTreePage/helpers/fontStyleHandler'
import {
  DiagnosesTextItem,
  NameTextItem,
  NodeContainer,
  NodeTextBox,
  RelativeTextItem
} from 'src/pages/FamilyTreePage/CustomNode/CustomNode.styles'

export interface NodeDataInterface {
  relativeInfo: string
  status: string
  relation?: string
  diagnoses?: string
}

interface Props {
  data: NodeDataInterface
  type?: string
}

const CustomNode: FC<Props> = ({ data, type }) => {
  const isEntryNode = type === 'entryNode'

  const diagnosesFontStyle = useMemo(() => diagnosesFontStyleHandler(data), [data])
  const relativeInfoFontStyle = useMemo(() => relativeInfoFontStyleHandler(data), [data])

  return (
    <NodeContainer isEntryNode={isEntryNode}>
      <Handle type='target' position={Position.Bottom} id='target-bottom'/>
      <Handle type='target' position={Position.Left} id='target-left'/>
      <Handle type='target' position={Position.Top} id='target-top'/>
      <Handle type='source' position={Position.Top} id='source-top'/>
      <Handle type='source' position={Position.Right} id='source-right'/>
      {data.status && <SvgTypes type={data.status}/>}
      <NodeTextBox>
        <NameTextItem relativeInfoFontStyle={relativeInfoFontStyle}>{data.relativeInfo}</NameTextItem>
        {data.relation && <RelativeTextItem>{data.relation}</RelativeTextItem>}
        {data.diagnoses && <DiagnosesTextItem diagnosesFontStyle={diagnosesFontStyle}>{data.diagnoses}</DiagnosesTextItem>}
      </NodeTextBox>
    </NodeContainer>
  )
}

export default CustomNode
