import { object, ref, string } from 'yup';
import { PasswordValidationErrors } from 'src/constans/validation';

export const validationSchema = object({
  password: string()
    .min(8, PasswordValidationErrors.Characters)
    .matches(/(?=.*[a-z])/, PasswordValidationErrors.Lowercase)
    .matches(/(?=.*[A-Z])/, PasswordValidationErrors.Uppercase)
    .matches(/(?=.*\d)|(?=.*\W)/, PasswordValidationErrors.NumberOrSymbol),
  confirmPassword: string().oneOf([ref('password'), null], 'Those passwords didn’t match. Please try again.'),
});
