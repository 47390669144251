import styled from 'styled-components';

export const FinishPageStyled = styled.div`
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  
  & p {
    width: 100%;
    max-width: 365px;
    font-size: ${props => (props.isMobile ? 16 : 18)}px;
    font-weight: 500;
    line-height: ${props => (props.isMobile ? 24 : 26)}px;
    
    &:last-of-type {
      flex-grow: 1;
    }
  }
  .submitButton {
    background-color: ${props => props.theme.colors.secondaryButtonDefaultColor};
    width: 256px;
    min-height: 48px;
    height: 48px;
    color: ${props => props.theme.colors.white};
    border-radius: 16px;
    border: ${props => (props.isMobile ? '1px solid' : 'none')};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    box-shadow: 0px -4px 16px rgba(149, 169, 200, 0.15);
    cursor: pointer;
    margin-bottom: 16px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    @media (hover: hover) {
      &:hover {
        background-color: ${props => props.theme.colors.secondaryButtonHoverColor};
      }
    }

    &:active {
      background-color: ${props => props.theme.colors.secondaryButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
`
