import { GET_FAMILY_TREE_FILE } from 'src/config'
import { isAndroid } from 'src/utils/inspectDeviceType'

export const openLinkInNewTab = (chatId: string): void => {
  const href = `${GET_FAMILY_TREE_FILE}?chatId=${chatId}`

  if (isAndroid) {
    window.open(href, '_blank')
  } else {
    const safariWindow: Window = window.open()
    safariWindow.location.href = href
  }
}
