import styled from 'styled-components';
import { LoginPageContent } from 'src/pages/Login/LoginPage/LoginPage.style';

export const CreatePasswordPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
  color: ${props => props.theme.colors.additionalText};

  @supports (height: 100svh) {
    min-height: 100svh;
  }
`

export const CreatePasswordPageContent = styled(LoginPageContent)`

`
